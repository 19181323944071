/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { Contract } from '@ethersproject/contracts';
import { useEffect, useState } from 'react';

import {
  ETHER_SERUM_CONTRACT,
  NFT_OLD_ADDRESS,
  NFT_TRACKS_ADDRESS,
  PLAY_VESTING_CONTRACT,
  POLYGON_SERUM_CONTRACT,
  RINKEBY_SERUM_CONTRACT,
  RINKEBY_TRANSFORMED_CONTRACT,
  SERUM_CONTRACT,
} from '../constants';

/*
  ~ What it does? ~

  Loads your local contracts and gives options to read values from contracts
  or write transactions into them

  ~ How can I use? ~

  const readContracts = useContractLoader(localProvider) // or
  const writeContracts = useContractLoader(userProvider)

  ~ Features ~

  - localProvider enables reading values from contracts
  - userProvider enables writing transactions into contracts
  - Example of keeping track of "purpose" variable by loading contracts into readContracts
    and using ContractReader.js hook:
    const purpose = useContractReader(readContracts,"YourContract", "purpose")
  - Example of using setPurpose function from our contract and writing transactions by Transactor.js helper:
    tx( writeContracts.YourContract.setPurpose(newPurpose) )
*/

const getContractAddress = (name, chainId) => {
  
  if (chainId === 4) { //rinkeby
    switch (name) {
      case 'Old':
        return NFT_OLD_ADDRESS;
      case 'play':
        return PLAY_VESTING_CONTRACT;
      case 'serum':
        return RINKEBY_SERUM_CONTRACT;
      default:
        return 'Please send valid contract name';
    }
  } else if (chainId === 80001) {  //mumbai
    switch (name) {
      case 'Old':
        return NFT_OLD_ADDRESS;
      case 'play':
        return PLAY_VESTING_CONTRACT;
      case 'serum':
        return SERUM_CONTRACT;
      default:
        return 'Please send valid contract name';
    }
  } else if (chainId === 1) { //ether
    switch (name) {
      case 'Old':
        return NFT_OLD_ADDRESS;
      case 'play':
        return PLAY_VESTING_CONTRACT;
      case 'serum':
        return ETHER_SERUM_CONTRACT;
      default:
        return 'Please send valid contract name';
    }
  } else if (chainId === 137) { //polygon
    switch (name) {
      case 'Old':
        return NFT_OLD_ADDRESS;
      case 'play':
        return PLAY_VESTING_CONTRACT;
      case 'serum':
        return POLYGON_SERUM_CONTRACT;
      default:
        return 'Please send valid contract name';
    }
  }
};

const loadContract = (contractName, signer, nftName, chainId) => {
  if (chainId && [80001, 4, 1, 137].includes(chainId)) {
    const contractAddress = getContractAddress(nftName, chainId);
    //console.log('contract address : ', contractAddress);
    const newContract = new Contract(
      contractAddress,
      require(`../contracts/${contractName}.json`).abi,
      signer
    );
    try {
      newContract.bytecode =
        require(`../contracts/${contractName}.json`).bytecode;
    } catch (e) {
      console.log(e);
    }
    return newContract;
  } else {
    return undefined;
  }
};

export default function useContractLoader(providerOrSigner, nftName, chainId) {
  const [contracts, setContracts] = useState();
  useEffect(() => {
    async function loadContracts() {
      if (
        // typeof network !== 'undefined' &&
        typeof providerOrSigner !== 'undefined'
      ) {
        try {
          // we need to check to see if this providerOrSigner has a signer or not
          let signer;
          let accounts;
          if (
            providerOrSigner &&
            typeof providerOrSigner.listAccounts === 'function'
          ) {
            accounts = await providerOrSigner.listAccounts();
          }

          if (accounts && accounts.length > 0) {
            signer = providerOrSigner.getSigner();
          } else {
            signer = providerOrSigner;
          }

          const contractList = require('../contracts/contracts');

          //console.log('contract list' , contractList);

          const newContracts = contractList.reduce(
            (accumulator, contractName) => {
              //console.log('contract reduce', contractName);
              console.log('name', nftName);
              accumulator[contractName] = loadContract(
                contractName,
                signer,
                nftName,
                chainId
              );
              return accumulator;
            },
            {}
          );
          console.log(newContracts);
          setContracts(newContracts);
        } catch (e) {
          console.log('ERROR LOADING CONTRACTS!!', e);
        }
      }
    }
    loadContracts();
  }, [providerOrSigner, nftName, chainId]);
  //console.log('contracts details', contracts);
  return contracts;
}
