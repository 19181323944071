import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout";
import ReactGA from "react-ga";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../components/particles";

const Home = () => {
  useEffect(() => {
    ReactGA.pageview("Home");
  }, []);
  return (
    <Fragment>
      <Layout theme="white">
        {/* not found page content */}
        <Container className="px-0 my-lg-5 my-md-5 my-xs-3 py-lg-5 py-md-5 py-xs-3">
          <Row className="align-items-center">
            <Col lg="6">
              <div className="jumbotron">
                <h1>NFTS are not just JPEGs!</h1>
                <p>
                  We are basically a group of NFT collectors who have been
                  involved in blockchain technology since inception. We love how
                  NFTs came out and how they became a main stream staple for
                  everybody.
                </p>
              </div>
            </Col>
            <Col lg="6">
              <img
                src={process.env.PUBLIC_URL + "images/comingsoon.png"}
                alt="logo images"
                className="img img-fluid"
              />
            </Col>
          </Row>
        </Container>
        <div className="mt-5 py-5 bg-dark">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <img
                  src={process.env.PUBLIC_URL + "images/question_mark.png"}
                  alt="logo images"
                  className="img-fluid"
                />
              </Col>
              <Col lg="6">
                <p className="text-white">
                  The biggest question that we were trying to tackle is what
                  about additional utility? How can the NFTs have a better way
                  of interacting with the user and providing a superior
                  experience? This is basically what drove us to work really
                  hard and seek like-minded contributors to figure out a way
                  that our beloved NFTs are not simply JPEGs! But how can we
                  make it into something that can change and evolve with us?
                  That seems impossible… or is it? The solution came when we
                  started thinking about transformations and as the Bored Ape
                  Yacht Club dropped their Serum, it really opened up other
                  possibilities for all us. Why not have a serum for every
                  collection, why not have a serum for every NFTs? Can serums be
                  an actual vehicle of artistic expression done by NFTs for any
                  NFTs??
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt-5 py-5">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <h3 className="text-warning">The Serum</h3>
                <p className="text-white">
                  That's where Serum NFTs started. They are a magical NFT serum
                  forged by the community with transformation power. This serum
                  embodies a way to artistically express a new NFT based on the
                  NFT that you already have. The serums themselves are either
                  genetic which means they can be applied to any NFT out there,
                  or collection specific which means they can only be applied to
                  a specific collection so that it would fit that collection's
                  actual layout and graphics. The other interesting innovation
                  that we've accomplished is that each NFT serum has a status,
                  meaning it can be used only when it is full and the the serum
                  in your wallet will reflect that. Once you use it and apply it
                  to your NFT, it will automatically be drained meaning you will
                  see the serum shape differs on any NFT platform out there.
                </p>
                <Link
                  to={process.env.PUBLIC_URL + "/serums"}
                  className="btn btn-warning text-white"
                >
                  Check Serums
                </Link>
              </Col>
              <Col lg="6">
                <img
                  src={process.env.PUBLIC_URL + "images/serumPlaceholder.png"}
                  alt="logo images"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt-5 bg-dark py-5">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <img
                  src={
                    process.env.PUBLIC_URL + "images/transformPlacholder.png"
                  }
                  alt="logo images"
                  className="img img-fluid"
                />
              </Col>
              <Col lg="6">
                <h3 className="text-warning">The Transformation Chamber</h3>
                <p className="text-white">
                  The transformation chamber is the place where you can take
                  your NFT and safely apply your serums to it. Out of the
                  chamber you will not only get your original NFT, unharmed, but
                  you will also receive an additional NFT with the
                  transformation applied to it. You can do this as many times as
                  you like to your NFTs to make sure they are as fun and
                  elaborate as you are! The transformed NFTs will be visible on
                  major platforms like opensea and others and you can freely
                  send them around or showcase your artistic innovation. So get
                  ready, our amazing journey will start soon to evolve all the
                  NFTs with love!
                </p>
                <Link
                  to={process.env.PUBLIC_URL + "/t-chamber"}
                  className="btn btn-warning  text-white"
                >
                  Check T Chamber
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </Fragment>
  );
};

export default Home;
