import React, { Fragment, useEffect, useState } from "react";
import { Row, Col,Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Avatar } from "@mui/material";

const ConfirmTransform = ({
  show,
  handleCloseParent,
  nftData,
  serumData,
  handleTransformParent,
}) => {
  const [showModal, setShowModal] = useState(show);
  const [loaded, setLoaded] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  const handleYes = () => {
    setShowModal(false);
    console.log("in modal");
    handleTransformParent();
  };

  const onImageLoaded = () => {
    setLoaded(true);
  };
  
  return (
    <Fragment>
      <Modal
        show={showModal}
        onHide={handleClose}
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transform {nftData.title} Nft</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col className="col-auto bg-dark2">
            <Avatar
            src={serumData.img}
            className="img img-fluid border-radius-0 serum-img-modal"
            alt="serum"
            loading="lazy"
            onLoad={onImageLoaded}
          />
              {!loaded && (
            <div className="image-container-overlay">
              <Spinner animation="border" variant="warning" />
            </div>
          )}
            </Col>
          </Row>
          <Row>
            <Col>
            You are about to transform your NFT,<br/> By doing so you agree to the
            terms and conditions of the site end affirm that you are the owner
            of the NFT and have the rights to perform the transformation.<br/>
            Are you sure you want to Apply {serumData.title} serum to{" "}
            {nftData.title} NFT?
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn" onClick={handleClose}>
            No
          </button>
          <button className="btn" onClick={handleYes}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ConfirmTransform;
