const ethers = require("ethers");

const loadContract = (contractAddress, ABI, provider) => {
  return new ethers.Contract(contractAddress, ABI, provider);
};

function memoizeContracts(contractAddress, ABI, provider) {
  if (!memoizeContracts.cache) {
    memoizeContracts.cache = {};
  }
  if (!memoizeContracts.cache[contractAddress]) {
    var result = loadContract(contractAddress, ABI, provider); //custom function
    memoizeContracts.cache[contractAddress] = result;
  }
  return memoizeContracts.cache[contractAddress];
}

export default memoizeContracts;