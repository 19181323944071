import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter,faDiscord ,faMedium} from '@fortawesome/free-brands-svg-icons'
const Footer = ({ theme }) => {
  return (
    <footer className="py-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
            Developed with &#10084; by <a href="https://nftpunks.org/"  className="text-warning" target="_blank">NFTPunks</a> 
            </div>
            <div className="col text-end">
           <ul className="float-end mb-0">
             <li>Follow Us</li>
             <li><a href="https://twitter.com/SerumNFTs" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faTwitter} size="lg"/></a></li>
             <li><a href="https://discord.com/invite/538K5Rs3uR" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord} size="lg" /></a></li>
             <li><a href="https://medium.com/@serumnfts" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMedium} size="lg" /></a></li>
           </ul>
            </div>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
