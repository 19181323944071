import React from "react";
import { Row, Col } from "react-bootstrap";

const PageTitle = ({ title }) => {


  return (
    <Row className="justify-content-center">
      <Col>
    <div className="page-title">
    <h1>{title}</h1>
    </div>  </Col>
    </Row>
  );
};

export default PageTitle;
