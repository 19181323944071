import PropTypes from "prop-types";
import React, { Fragment ,useEffect} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import {useHistory} from "react-router-dom";

const Layout = ({ children, theme }) => {
  let history = useHistory();

//   useEffect(() => {
//     if(!window.$isValidUser){
//       history.push("/");
//     }
// }, []);

  return (
    <Fragment>
      <Header theme={theme} />
      <div className="h-min-100vh py-5">
      {children}</div>
      <Footer theme={theme} />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
};

export default Layout;
