import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
  Form,
} from "react-bootstrap";
import SerumTooltip from "../Tooltip";
import { SerumClassify, SerumStatus, categories } from "../../Enums";
import Select from "react-select";

const Filteration = ({
  selectedClassify,
  selectedStatus,
  selectedCollectionCat,
  selectedShowMyNFTs,
  isShowStatus = false,
  collectionOptions,
  selectedCategories,
}) => {
  const [radioValue, setRadioValue] = useState("");
  const [selectedClassifyValue, setSelectedClassifyValue] = useState([
    SerumClassify.Generic,
    SerumClassify.PerCollection,
    SerumClassify.PerCard,
  ]);
  const fLevelData = categories.map((cat, index) => ({
    label: cat.fLevel,
    value: cat.fLevel,
  }));
  const sLevelData = categories.map((cat) =>
    cat.sLevel.map((scat) => ({ label: scat.name, value: scat.name }))
  );
  const tLevel = categories.map((cat) =>
    cat.sLevel.map((scat) => scat.tLevel.map((tCat) => tCat.name))
  );
  const tLevelSelection = tLevel.flat(2);
  const tLevelData = tLevelSelection.map((tCat) => ({
    label: tCat,
    value: tCat,
  }));
  const [fLevelOptions, setFLevelOptions] = useState(fLevelData);

  const [sLevelOptions, setSLevelOptions] = useState(sLevelData);
  const [tLevelOptions, setTLevelOptions] = useState(tLevelData);
  const [ selectedCategoLevel1, setSelectedCategoLevel1] = useState({
    label: "General",
    value: "General",
  });
  const [selectedCategoLevel2, setSelectedCategoLevel2] = useState();
  const [selectedCategoLevel3, setSelectedCategoLevel3] = useState();
  useEffect(() => {
    handleChangeCatLevel(
      {
        label: "General",
        value: "General",
      },
      1
    );
  }, []);
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#f0ad4e" : "#242735",
      color:  "#fff",
      "&:hover,&:focus,&:active": {
        backgroundColor: "#f0ad4e",
        color: "#fff",
      },
    }),
  };

  const [selectedCollectionCatValue, setSelectedCollectionCatValue] = useState({
    value: "",
    label: (
      <div>
        <img
          className="border-radius-50"
          src={process.env.PUBLIC_URL + "images/icons/nftLogo.png"}
          height="30px"
          width="30px"
        />
        &nbsp;All{" "}
      </div>
    ),
  });

  const [isOnlyMyNFTs, setIsOnlyMyNFTs] = useState(false);

  const handleChangeClassify = (data) => {
    setSelectedClassifyValue(data);
    selectedClassify(
      data,
      radioValue,
      selectedCollectionCatValue.value,
       "",//selectedCategoLevel1.value,,
      selectedCategoLevel2,
      selectedCategoLevel3
    );
  };
  const handleChangeCollectionCat = (data) => {
    setSelectedCollectionCatValue(data);
    selectedCollectionCat(
      selectedClassifyValue,
      radioValue,
      data.value,
       "",//selectedCategoLevel1.value,,
      selectedCategoLevel2,
      selectedCategoLevel3
    );
  };
  const handleChangeCatLevel = (e, level) => {
    if (level == 1) {
      setSelectedCategoLevel1(e);
      setSelectedCategoLevel2(null);
      setSelectedCategoLevel3(null);
      const filteredCategories = categories.filter(
        (cat) => cat.fLevel == e.value
      );
      const sLevelData = filteredCategories.map((cat) =>
        cat.sLevel.map((scat) => ({ label: scat.name, value: scat.name }))
      );
      setSLevelOptions(sLevelData.flat());
      const tLevel = filteredCategories.map((cat) =>
        cat.sLevel.map((scat) => scat.tLevel.map((tCat) => tCat.name))
      );
      const tLevelSelection = [...new Set(tLevel.flat(2))];
      const tLevelData = tLevelSelection.map((tCat) => ({
        label: tCat,
        value: tCat,
      }));
      setTLevelOptions(tLevelData.flat(2));
      var level2names = selectedCategoLevel2?.map(function (item) {
        return item["value"];
      });
      var level3names = selectedCategoLevel3?.map(function (item) {
        return item["value"];
      });
      selectedCategories(
        selectedClassifyValue,
        radioValue,
        selectedCollectionCatValue.value,
       "", //e.value,
        level2names,
        level3names
      );
    } else if (level == 2) {
      setSelectedCategoLevel2(e);
      setSelectedCategoLevel3(null);
      var names = e?.map(function (item) {
        return item["label"];
      });
      var level3names = selectedCategoLevel3?.map(function (item) {
        return item["value"];
      });
      const filteredCategories = categories.filter(
        (cat) => cat.fLevel ==  selectedCategoLevel1.value,
      );
      const tLevel = filteredCategories.map((cat) => {
        return cat.sLevel
          .map(
            (scat) =>
              names.includes(scat.name) && scat.tLevel.map((tCat) => tCat.name)
          )
          .filter((tl) => tl !== false);
      });
      const tLevelSelection = [...new Set(tLevel.flat(2))];
      const tLevelData = tLevelSelection.map((tCat) => ({
        label: tCat,
        value: tCat,
      }));
      setTLevelOptions(tLevelData.flat(2));
      selectedCategories(
        selectedClassifyValue,
        radioValue,
        selectedCollectionCatValue.value,
         "",// "",//selectedCategoLevel1.value,.value,,
        names,
        level3names
      );
    } else {
      var names = e?.map(function (item) {
        return item["label"];
      });
      var level2names = selectedCategoLevel2?.map(function (item) {
        return item["value"];
      });
      setSelectedCategoLevel3(e);
      selectedCategories(
        selectedClassifyValue,
        radioValue,
        selectedCollectionCatValue.value,
        "",// "",// "",//selectedCategoLevel1.value,.value,,
        level2names,
       names
      );
    }
  
  };
  const handleChangeStatus = (data) => {
    setRadioValue(data);
    selectedStatus(
      data,
      selectedClassifyValue,
      selectedCollectionCatValue.value,
       "",//selectedCategoLevel1.value,,
      selectedCategoLevel2,
      selectedCategoLevel3
    );
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={12} md={12} sm={12}>
            <Row className="mt-3 align-items-start">
                <Col>
                  <Select
                    options={fLevelOptions}
                    onChange={(e) => handleChangeCatLevel(e, 1)}
                    value={ selectedCategoLevel1}
                    menuPortalTarget={document.body}
                    className={`w-100 serum-select `}
                    styles={customStyles}
                  />
                </Col>
                <Col>
                  <Select
                    options={sLevelOptions.flat()}
                    onChange={(e) => handleChangeCatLevel(e, 2)}
                    menuPortalTarget={document.body}
                    value={selectedCategoLevel2}
                    className={`w-100 serum-select showopacity `}
                    styles={customStyles}
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                  />
                </Col>
                <Col>
                  <Select
                    options={tLevelOptions}
                    onChange={(e) => handleChangeCatLevel(e, 3)}
                    value={selectedCategoLevel3}
                    className={`w-100 serum-select `}
                    styles={customStyles}
                    isMulti={true}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    isSearchable={true}
                  />
                </Col>
                <Col>
                      <Select
                        options={collectionOptions}
                        onChange={(v) => handleChangeCollectionCat(v)}
                        value={selectedCollectionCatValue}
                        className={`w-100 serum-select ${
                          selectedClassifyValue.includes(
                            SerumClassify.PerCollection
                          )
                            ? "showopacity"
                            : ""
                        }`}
                        styles={customStyles}
                      />
                    <a
                        target="_blank"
                        className="text-muted"
                        href={selectedCollectionCatValue.link}
                      >
                        Link in Opensea
                      </a>
                    </Col>  
                    <Col className="col-auto d-flex align-items-center">
                      <Form.Check
                        inline
                        label="Only my NFTs"
                        name="onlyMyNFTs"
                        type="checkbox"
                        id={`onlyMyNFTs`}
                        checked={isOnlyMyNFTs}
                        onChange={(e) => {
                          setIsOnlyMyNFTs(e.target.checked);
                          selectedShowMyNFTs(e.target.checked);
                          setSelectedCollectionCatValue({
                            value: "",
                            label: (
                              <div>
                                <img
                                  className="border-radius-50"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "images/icons/nftLogo.png"
                                  }
                                  height="30px"
                                  width="30px"
                                />
                                &nbsp;All{" "}
                              </div>
                            ),
                          });
                        }}
                      />
                    </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <Row>
                    <Col>
                      <ToggleButtonGroup
                        type="checkbox"
                        defaultValue={selectedClassifyValue}
                        className="checkbox-group w-100"
                        onChange={handleChangeClassify}
                      >
                        <ToggleButton
                          id="tbg-check-generic"
                          value={SerumClassify.Generic}
                        >
                          <SerumTooltip title="Filter by Generic Serum">
                            <img
                              src={
                                selectedClassifyValue.includes(
                                  SerumClassify.Generic
                                )
                                  ? process.env.PUBLIC_URL +
                                    "images/icons/generic_selected.png"
                                  : process.env.PUBLIC_URL +
                                    "images/icons/generic.png"
                              }
                              alt="logo images"
                              className="img-fluid"
                              width="30px"
                            />
                          </SerumTooltip>
                        </ToggleButton>

                        <ToggleButton
                          id="tbg-check-perCol"
                          value={SerumClassify.PerCollection}
                        >
                          <SerumTooltip title="Filter by Per Collection Serum">
                            <img
                              src={
                                selectedClassifyValue.includes(
                                  SerumClassify.PerCollection
                                )
                                  ? process.env.PUBLIC_URL +
                                    "images/icons/percollection_selected.png"
                                  : process.env.PUBLIC_URL +
                                    "images/icons/percollection.png"
                              }
                              alt="logo images"
                              className="img-fluid"
                              width="30px"
                            />
                          </SerumTooltip>
                        </ToggleButton>
                        <ToggleButton
                          id="tbg-check-perCard"
                          value={SerumClassify.PerCard}
                        >
                          <SerumTooltip title="Filter by Per Card Serum">
                            <img
                              src={
                                selectedClassifyValue.includes(
                                  SerumClassify.PerCard
                                )
                                  ? process.env.PUBLIC_URL +
                                    "images/icons/percard_selected.png"
                                  : process.env.PUBLIC_URL +
                                    "images/icons/percard.png"
                              }
                              alt="logo images"
                              className="img-fluid"
                              width="30px"
                            />
                          </SerumTooltip>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Col>
                  
                  </Row>
                  {/* <label>Specification:</label> */}
                </Col>
                <Col>
                  {/* <label>Status:</label> */}
                  <ButtonGroup
                    className={`w-100 radio-group ${
                      isShowStatus ? "showopacity" : ""
                    }`}
                  >
                    <ToggleButton
                      id="radio-all"
                      type="radio"
                      name="serumStatus"
                      value=""
                      checked={radioValue === ""}
                      onClick={(e) => handleChangeStatus("")}
                      className={
                        isShowStatus ? "showopacity" : "visually-hidden"
                      }
                    >
                      <img
                        src={
                          radioValue === ""
                            ? process.env.PUBLIC_URL +
                              "images/icons/full_selected.png"
                            : process.env.PUBLIC_URL + "images/icons/full.png"
                        }
                        alt="logo images"
                        className="img-fluid"
                        width="40px"
                      />
                      <img
                        src={
                          radioValue === ""
                            ? process.env.PUBLIC_URL +
                              "images/icons/empty_selected.png"
                            : process.env.PUBLIC_URL + "images/icons/empty.png"
                        }
                        alt="logo images"
                        className="img-fluid"
                        width="40px"
                      />
                    </ToggleButton>
                    <SerumTooltip title="Filter by Full Serum">
                      <ToggleButton
                        id="radio-fullSerum"
                        type="radio"
                        name="serumStatus"
                        value={SerumStatus.Full}
                        checked={radioValue === SerumStatus.Full}
                        onClick={(e) => handleChangeStatus(SerumStatus.Full)}
                        className={
                          isShowStatus ? "showopacity" : "visually-hidden"
                        }
                      >
                        <img
                          src={
                            radioValue === SerumStatus.Full
                              ? process.env.PUBLIC_URL +
                                "images/icons/full_selected.png"
                              : process.env.PUBLIC_URL + "images/icons/full.png"
                          }
                          alt="logo images"
                          className="img-fluid"
                          width="40px"
                        />
                      </ToggleButton>
                    </SerumTooltip>
                    <SerumTooltip title="Filter by Empty Serum">
                      <ToggleButton
                        id="radio-emptySerum"
                        type="radio"
                        name="serumStatus"
                        value={SerumStatus.Empty}
                        checked={radioValue === SerumStatus.Empty}
                        onClick={(e) => handleChangeStatus(SerumStatus.Empty)}
                        className={
                          isShowStatus ? "showopacity" : "visually-hidden"
                        }
                      >
                        <img
                          src={
                            radioValue === SerumStatus.Empty
                              ? process.env.PUBLIC_URL +
                                "images/icons/empty_selected.png"
                              : process.env.PUBLIC_URL +
                                "images/icons/empty.png"
                          }
                          alt="logo images"
                          className="img-fluid"
                          width="40px"
                        />
                      </ToggleButton>
                    </SerumTooltip>
                  </ButtonGroup>
                </Col>
              </Row>
             
           
        </Col>
      </Row>
    </Container>
  );
};

export default Filteration;
