import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Nav, Tab } from "react-bootstrap";
import { Web3Provider } from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import ReactGA from "react-ga";

import useContractLoader from "../connectors/hooks/ContractLoader";
import useUserProvider from "../connectors/hooks/UserProvider";
import localProvider from "../connectors/helpers/Provider";

import Filteration from "../components/filteration";
import SerumCard from "../components/serumCard";
import CardLoader from "../components/cardLoader";
import PageTitle from "../components/pageTitle";
import Layout from "../layout";
import NewSerum from "../components/newSerum";

import { DEPLOYER } from "../connectors/constants";
import { SerumClassify, SerumStatus, collections } from "../Enums";

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
}

async function fetchIPFSJSON(ipfsURI) {
  const resp = await fetch("https://dweb.link/ipfs/" + ipfsURI);
  return resp.json();
}

async function getNFTImage(tokenMetaUri) {
  const metadata = await fetchIPFSJSON(tokenMetaUri);

  if (metadata.image) {
    metadata.image = makeGatewayURL(metadata.image);
  }

  return metadata.image;
}

const Serums = () => {
  const [injectedProvider, setInjectedProvider] = useState();
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const currentWallet = useUserAddress(userProvider);

  const [allSerums, setAllSerums] = useState();
  const [mySerums, setMySerums] = useState([]);
  const [filteredAllSerums, setFilteredAllSerums] = useState([]);
  const [filteredMySerums, setFilteredMySerums] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tabKey, setTabKey] = useState("allserums");
  const [allSerumsData, setSerumsData] = useState();

  const [SerumContract, setSerumContract] = useState();

  const selectedChainId =
    userProvider && userProvider._network && userProvider._network.chainId;

  const serumNftContracts = useContractLoader(
    userProvider,
    "serum",
    selectedChainId
  );

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    ReactGA.pageview("Serum Screen");
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getCurrentSerums = useCallback(
    async (contract) => {
      try {
        let currentSerums = [];
        const allTokens = await contract.getAllTokens();

        for (var token of allTokens) {
          const emptyAddress = /^0x0+$/.test(token.owner);
          if (!emptyAddress) {
            const image = await getNFTImage(
              token.status == 2 ? token.fullUri : token.emptyUri
            );

            const tempSerum = {
              id: parseInt(token.id._hex.toString(), 16),
              img: image,
              description: token.description,
              status: parseInt(token.status._hex.toString(), 16),
              classification: parseInt(token.classify._hex.toString(), 16),
              isOwnedByUser: token.owner === currentWallet ? true : false,
              title: token.name,
              owner: token.owner,
              fee:
                parseInt(token.transformationFee._hex.toString(), 16) /
                  10 ** 18 || 0,
              category: token.category,
              price: parseInt(token.price._hex.toString(), 16) / 10 ** 18 || 0,
              collectionAddress: token.collectionAddress,
              cardNum: token.cardNum,
            };

            currentSerums.push(tempSerum);
          }
        }
        console.log("currentSerums", currentSerums);
        return currentSerums;
      } catch (error) {
        console.log("error at signer" + error);
        return [];
      }
    },
    [currentWallet]
  );

  const loadNftData = useCallback(async () => {
    if (selectedChainId && [80001, 4, 1, 137].includes(selectedChainId)) {
      const Contract = serumNftContracts ? serumNftContracts["SerumNFT"] : "";
      Contract && setSerumContract(Contract);

      const getSerums = Contract && (await getCurrentSerums(Contract));
      const serumsData = getSerums && [...getSerums];

      // try{
      //   const currentAddress = Contract && (await signer.getAddress());
      //   !!currentAddress && setCurrentuser(currentAddress);
      // }
      // catch (error) {
      //   console.log('error at signer'+error);
      //   return [];
      // }
      !!serumsData && setSerumsData(serumsData);
      !!serumsData &&
        setAllSerums(serumsData.filter((serum) => serum.owner === DEPLOYER));

      !!serumsData &&
        setMySerums(serumsData.filter((serum) => serum.isOwnedByUser === true));

      !!serumsData &&
        setFilteredAllSerums(
          serumsData.filter((serum) => serum.owner === DEPLOYER)
        );
      !!serumsData &&
        setFilteredMySerums(
          serumsData.filter((serum) => serum.isOwnedByUser === true)
        );

      !!serumsData && setLoader(false);
    }
  }, [getCurrentSerums, serumNftContracts, selectedChainId]);

  useEffect(() => {
    if (!allSerums) {
      loadNftData();
    }
  }, [loadNftData, allSerums]);

  const changeClassifySearch = (
    data,
    status,
    collectionCat,
    level1,
    level2,
    level3
  ) => {
    handleSearch(data, status, collectionCat, level1, level2, level3);
  };

  const changeStatusSearch = (
    data,
    classify,
    collectionCat,
    level1,
    level2,
    level3
  ) => {
    handleSearch(classify, data, collectionCat, level1, level2, level3);
  };
  const changeCollectionCatSearch = (
    classify,
    status,
    data,
    level1,
    level2,
    level3
  ) => {
    handleSearch(classify, status, data, level1, level2, level3);
  };
  const changeCategoriesSearch = (
    classify,
    status,
    collectionCat,
    level1,
    level2,
    level3
  ) => {
    handleSearch(classify, status, collectionCat, level1, level2, level3);
  };
  const handleSearch = (
    classify,
    status,
    collectionCat,
    level1,
    level2,
    level3
  ) => {
    let filteredAllData = allSerums?.filter((serum) => {
      const categoryList = serum.category.split(",");
      console.log(categoryList[0]);
      return (
        classify.includes(serum.classification) &&
        (collectionCat === serum.collectionAddress || collectionCat === "") &&
        (categoryList[0] == level1 || level1 == "") &&
        (level2?.includes(categoryList[1]) || level2 == undefined||level2.length==0)&&
        (level3?.includes(categoryList[2]) || level3 == undefined||level3.length==0)
      );
    });
    let filteredMyData = mySerums?.filter((serum) => {
      const categoryList = serum.category.split(",");
      console.log(categoryList[0]);
      return (
        classify.includes(serum.classification) &&
        (status === serum.status || status === "") &&
        (collectionCat === serum.collectionAddress || collectionCat === "")&&
        (categoryList[0] == level1 || level1 == "") &&
        (level2?.includes(categoryList[1]) || level2 == undefined||level2.length==0)&&
        (level3?.includes(categoryList[2]) || level3 == undefined||level3.length==0)
      );
    });

    setFilteredAllSerums(filteredAllData);
    setFilteredMySerums(filteredMyData);
  };

  const serumsBuyFinished = () => {
    //fired when buy finished
  };

  const setselectedCollection = (value) => {
    if (value) {
      const filteredOptions = options.filter((elem) =>
        allSerumsData.find(
          ({ collectionAddress }) => elem.value === collectionAddress
        )
      );
      setOptions([
        {
          value: "",
          label: (
            <div>
              <img
                className="border-radius-50"
                src={process.env.PUBLIC_URL + "images/icons/nftLogo.png"}
                height="30px"
                width="30px"
              />
              &nbsp;All{" "}
            </div>
          ),
        },
        ...filteredOptions,
      ]);
    } else {
      setOptions(selectOptions);
    }
  };
  let selectOptions = [
    {
      value: "",
      label: (
        <div>
          <img
            className="border-radius-50"
            src={process.env.PUBLIC_URL + "images/icons/nftLogo.png"}
            height="30px"
            width="30px"
          />
          &nbsp;All{" "}
        </div>
      ),
    },
  ];
  collections
    .sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .forEach((col) => {
      let singleoption = {
        value: col.address,
        link: col.openSeaLink,
        label: (
          <div>
            <img
              className="border-radius-50"
              src={col.image}
              height="30px"
              width="30px"
            />
            &nbsp;{col.name}{" "}
          </div>
        ),
      };
      selectOptions.push(singleoption);
    });
  const [options, setOptions] = useState(selectOptions);
  return (
    <Fragment>
      <Layout>
        <PageTitle title="serums" />
        <Filteration
          selectedClassify={changeClassifySearch}
          selectedStatus={changeStatusSearch}
          selectedCollectionCat={changeCollectionCatSearch}
          isShowStatus={tabKey === "myserums" ? true : false}
          selectedShowMyNFTs={setselectedCollection}
          collectionOptions={options}
          selectedCategories={changeCategoriesSearch}
        />
        <Container className="my-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="allserums">
            <Row>
              <Col sm={3} className="side-tabs-left">
                <Nav
                  variant="pills"
                  className="flex-column side-tabs"
                  onSelect={(k) => setTabKey(k)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="allserums">All Serums</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="myserums">My Serums</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="newserum">Create Serum</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="allserums">
                    {loader ? (
                      <Row>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {filteredAllSerums.length > 0 ? (
                          filteredAllSerums.map((serum, index) => (
                            <Col
                              key={index}
                              lg={3}
                              md={4}
                              sm={12}
                              className="mb-4"
                            >
                              <SerumCard
                                data={serum}
                                contract={SerumContract}
                                user={currentWallet}
                                serumsBuyFinished={serumsBuyFinished}
                                selectedChainId={selectedChainId}
                              />
                            </Col>
                          ))
                        ) : (
                          <Col>
                            <h4 className="no-data text-center py-5">
                              We are working hard to add more Serums!
                            </h4>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="myserums">
                    {!currentWallet ? (
                      <Row>
                        <Col>
                          <h4 className="no-data text-center py-5">
                            You Should Login First to see Your Serums
                          </h4>
                        </Col>
                      </Row>
                    ) : loader ? (
                      <Row>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                        <Col lg={3} md={4} sm={12}>
                          <CardLoader />
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {filteredMySerums.length > 0 ? (
                          filteredMySerums.map((serum, index) => (
                            <Col
                              key={index}
                              lg={3}
                              md={4}
                              sm={12}
                              className="mb-4"
                            >
                              <SerumCard
                                data={serum}
                                selectedChainId={selectedChainId}
                              />
                            </Col>
                          ))
                        ) : (
                          <Col>
                            <h4 className="no-data text-center py-5">
                              We are working hard to add more Serums!
                            </h4>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="newserum">
                    {!currentWallet ? (
                      <Row>
                        <Col>
                          <h4 className="no-data text-center py-5">
                            You Should Login First to see Your Serums
                          </h4>
                        </Col>
                      </Row>
                    )  : (
                      <Row className="justify-content-center">
                     
                            <Col
                            lg={8}
                            md={10}
                            xs={12}
                              className="mb-4 "
                            >
                            <NewSerum/>
                            </Col>
                   
                      </Row>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </Fragment>
  );
};

export default Serums;
