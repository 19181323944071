import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Web3Auth } from "../../connectors/Auth.js";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const header = document.querySelector("header");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <header
      className={`header--absolute  ${scroll > headerTop ? "stick" : ""}`}
    >
      <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <Logo image={"/images/logo.png"} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            <Link
                to={process.env.PUBLIC_URL + "/"}
                className="nav-link"
              >
                Home
              </Link>
              <Link
                to={process.env.PUBLIC_URL + "/serums"}
                className="nav-link"
              >
                Serums
              </Link>
              <Link
                to={process.env.PUBLIC_URL + "/t-chamber"}
                className="nav-link"
              >
                TChamber
              </Link>
              <Link to={process.env.PUBLIC_URL + "/nfts"} className="nav-link">
                NFTs
              </Link>
         
            </Nav>
            <Web3Auth
                onConnectAccount={() => {
                  setConnected(true);
                }}
                onDisconnectAccount={() => {
                  setConnected(false);
                }}
              />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
