import React, { Fragment,useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout";
import ReactGA from "react-ga";

const NotAuth = () => {
  useEffect(()=>{
    ReactGA.pageview("Not Auth Screen")
  },[])
  return (
    <Fragment>
      <Layout theme="white">
        {/* not found page content */}
        <div className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-9 m-auto text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="no-auth-container">
                    <h2>Please Log in first!</h2>
                    <p>
                      You Must Login First to access this page
                    </p>
                    <Link className="text-white" to={process.env.PUBLIC_URL + "/serums"}>
                      Back To Homepage
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default NotAuth;
