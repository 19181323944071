import React from "react";
import { Tooltip  } from "@mui/material";
const SerumTooltip = ({children,title}) => {
    return(
<Tooltip
classes={{
  tooltip: "serum-tooltip",
  arrow: "serum-tooltip-arrow",
}}
className=""
arrow
placement="top"
title={title}
>
{children}
</Tooltip>
    )}
export default SerumTooltip;