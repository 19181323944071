import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import NFTCard from '../nftCard';
import { Modal } from 'react-bootstrap';
import { useUserProvider } from '../../connectors/hooks';
import { useUserAddress } from 'eth-hooks';
import { Web3Provider } from '@ethersproject/providers';
import networksMetaData from '../../connectors/helpers/loadUserNFTs/networksMetaData';
import loadUserNFTData from '../../connectors/helpers/loadUserNFTs/loadUserNFTData';
import CardLoader from '../cardLoader';

const NftModal = ({ show, handleCloseParent }) => {
  const [showModal, setShowModal] = useState(show);
  const [injectedProvider, setInjectedProvider] = useState();
  const userProvider = useUserProvider(injectedProvider, undefined);
  const currentWallet = useUserAddress(userProvider);
  // const signer = userProvider?.getSigner();
  const [allMyNfts, setAllMyNFts] = useState();
  const [myNfts, setMyNfts] = useState([]);
  const [loader, setLoader] = useState(true);

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getCurrentUserTokens = useCallback(async () => {
    if (currentWallet) {
      try {
        console.log(window.ethereum);
        let network = await userProvider.getNetwork();
        let chainId = network.chainId;
        console.log('chainId', chainId);
        // let userAddress = await signer.getAddress();
        // console.log('userAddress', userAddress);
        let chainMetaData = networksMetaData[chainId];
        console.log('network metadata', chainMetaData);
        chainMetaData.provider = userProvider;
        return await loadUserNFTData(chainMetaData, currentWallet);
      } catch (error) {
        console.log('error at signer' + error);
        return [];
      }
    }

    return undefined;
  }, [currentWallet, userProvider]);

  useEffect(() => {
    async function fetchUserData() {
      setAllMyNFts(await getCurrentUserTokens());
    }
    if (!allMyNfts) fetchUserData();
  }, [getCurrentUserTokens]);

  useEffect(() => {
    if (allMyNfts) {
      console.log(allMyNfts);
      setMyNfts(allMyNfts.filter((ntf) => !ntf.isTransformed));
      setLoader(false);
    }
  }, [allMyNfts]);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent('0');
  };

  return (
    <Fragment>
      <Modal
        show={showModal}
        onHide={handleClose}
        scrollable={true}
        size='lg'
        backdrop='static'
        className='modal-full-screen'
      >
        <Modal.Header closeButton>
          <Modal.Title>My NFTs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader ? (
            <Row>
              <Col lg={3} md={4} sm={12}>
                <CardLoader />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <CardLoader />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <CardLoader />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <CardLoader />
              </Col>
            </Row>
          ) : (
            <Row>
              {myNfts && myNfts.length > 0 ? (
                myNfts.map((NFT, index) => (
                  <Col key={index} lg={3} md={3} sm={12} className='mb-4'>
                    <NFTCard
                      data={NFT}
                      isFromModal={true}
                      handleCloseParent={handleCloseParent}
                    />
                  </Col>
                ))
              ) : (
                <Col>
                  <h4 className='no-data text-center text-black py-5'>
                    You Don't have NFTs
                  </h4>
                </Col>
              )}
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default NftModal;
