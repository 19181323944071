import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { TwitterIcon, TwitterShareButton } from "react-share";

const ShareToTwitterModal = ({ show, handleCloseParent,selectedChainId,selectedMention,currentWallet}) => {
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };



  return (
    <Fragment>
      <Modal
        show={showModal}
        onHide={handleClose}
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>TransFormation Done!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col className="mb-4">Congratulation Your Transformation done</Col>
            <TwitterShareButton
            beforeOnClick={()=>{handleClose()}}
            url={`${process.env.REACT_APP_OPENSEA_LINK}${currentWallet}`}
            title={`Fresh out of the ${
              [80001, 137].includes(selectedChainId) ? "#Polygon" : "#Ethereum"
            }  #NFT Transformation Chamber, Congratulations!
            #NFTCommmunity  ${selectedMention?selectedMention:""}  Get yours! `}
                    >
                      <TwitterIcon size={32} round /> Share to Twitter
                    </TwitterShareButton>
           {/*<Col>{data.title} serum cost {data.price} <br/>Are you sure you want to Buy it?</Col> */}
            </Row>
        </Modal.Body>
        <Modal.Footer>
    <button className="btn"  onClick={handleClose}>Close</button>
  </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ShareToTwitterModal;
