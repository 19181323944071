import { Button } from 'antd';
import React, { useState } from 'react';
import Address from './Address';
import Balance from './Balance';
import { useHistory } from 'react-router-dom';
export default function Account({
  address,
  userProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
  //changeAccountState,
  //DisconnectAccountState,
}) {
  let history = useHistory();
  const [isPolygon, setIsPolygon] = useState(true);
  const modalButtons = [];

  async function Disconnect() {
    await logoutOfWeb3Modal();
    //DisconnectAccountState();
    history.push('/serums');
  }

  async function Connect() {
    await loadWeb3Modal();
    //changeAccountState();
    history.push('/nfts');
  }

  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <>
          {/* <Address address={address} blockExplorer={blockExplorer} /> */}
          {<Balance address={address} provider={userProvider} price={price} />}
          <img
            src={
              (userProvider?._network?.chainId === 80001 || userProvider?._network?.chainId === 137)
                ? process.env.PUBLIC_URL + 'images/icons/polygon.svg'
                : // : process.env.PUBLIC_URL + 'images/icons/Ethereum.png'
                  process.env.PUBLIC_URL + 'images/icons/polygon-inactive.png'
            }
            alt='logo images'
            className='img-fluid'
            width='40px'
          />
          <img
            src={
              (userProvider?._network?.chainId === 4||userProvider?._network?.chainId === 1)
                ? process.env.PUBLIC_URL + 'images/icons/Ethereum.png'
                : process.env.PUBLIC_URL + 'images/icons/Ethereum-inactive.png'
            }
            alt='logo images'
            className='img-fluid mx-3'
            width='40px'
          />
          <img
            src={process.env.PUBLIC_URL + 'images/icons/disconnect.png'}
            alt='logo images'
            className='img-fluid'
            onClick={Disconnect}
            width='50px'
          />
        </>
      );
    } else {
      modalButtons.push(
        <img
          src={process.env.PUBLIC_URL + 'images/icons/metamasklogo.png'}
          alt='logo images'
          className='img-fluid'
          onClick={Connect}
          width='40px'
        />
      );
    }
  }

  return <div>{modalButtons}</div>;
}
