import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast as networkToast } from 'react-toastify';
import { useUserAddress } from 'eth-hooks';
import { Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';

import WalletConnectProvider from '@walletconnect/web3-provider';

import { useUserProvider } from './hooks';
import { Account } from './components';

import { ETH_NAME, NETWORK, POL_NAME } from './constants';

networkToast.configure({
  position: 'top-center',
  autoClose: false,
  draggable: false,
});

// const targetNetwork = NETWORKS.mumbai;

const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log('📡 Connecting to Smart Chain - Testnet');

// const localProviderUrl = targetNetwork.rpcUrl;
// const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER
//   ? process.env.REACT_APP_PROVIDER
//   : localProviderUrl;

// if (DEBUG) console.log('🏠 Connecting to provider:', localProviderUrlFromEnv);
// const localProvider = new StaticJsonRpcProvider(localProviderUrlFromEnv);

// const blockExplorer = targetNetwork.blockExplorer;

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      // options: {
      //   rpc: {
      //     [targetNetwork.chainId]: targetNetwork.rpcUrl,
      //   },
      // },
    },
  },
});

function Web3Auth() {
  let networkDisplay;
  let history = useHistory();
  let location = useLocation();

  const [injectedProvider, setInjectedProvider] = useState();
  const userProvider = useUserProvider(injectedProvider, undefined);
  const address = useUserAddress(userProvider);

  // const localChainId =
  //   localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userProvider && userProvider._network && userProvider._network.chainId;

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    history.push('/');
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  if (web3Modal.cachedProvider === '' && address === '') {
    const pathname = location.pathname;
    if (
      pathname.toLowerCase() === '/nfts' ||
      pathname.toLowerCase() === '/t-chamber'
    ) {
      history.push('/not-auth');
    }
  }

  if (selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    if (![80001, 4, 1, 137].includes(selectedChainId)) {
      networkToast.warning(
        <div>
          {/* <Alert severity='error'> */}
          <p>⚠️ Wrong Network</p>
          <div>
            You have <b>{networkSelected && networkSelected.name}</b> selected
            and you need to be on {ETH_NAME} or {POL_NAME}.
            {/* and you need to be on <b>{networkLocal && networkLocal.name}</b>. */}
          </div>
          {/* </Alert> */}
        </div>,
        {
          toastId: 1,
        }
      );
      networkDisplay = networkSelected.name;
      // !networkDisplay &&
      // networkToast.warning(
      //   <div>
      //     {/* <Alert severity='error'> */}
      //     <p>⚠️ Wrong Network</p>
      //     <div>
      //       You have <b>{networkSelected && networkSelected.name}</b> selected
      //       and you need to be on mumbai or rinkeby.
      //       {/* and you need to be on <b>{networkLocal && networkLocal.name}</b>. */}
      //     </div>
      //     {/* </Alert> */}
      //   </div>,
      //   {
      //     toastId: 1,
      //   }
      // );
      // console.log(networkDisplay);
    } else {
      const networkSelected = NETWORK(selectedChainId);
      networkDisplay = (
        <div
          style={{
            color: networkSelected?.color,
          }}
        >
          {networkSelected.name}
        </div>
      );
    }
  }
  return (
    <div>
      {address ? networkDisplay : ''}
      <Account
        address={address}
        userProvider={userProvider}
        price={0}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        // blockExplorer={blockExplorer}
      />
    </div>
  );
}

export { Web3Auth };
