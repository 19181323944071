import React, { Fragment, useEffect } from "react";
import Particle from "../components/particles";
import ReactGA from "react-ga";

const ComingSoon = () => {

  useEffect(()=>{
    ReactGA.pageview("Coming Soon Screen")
  },[])
  return (
    <Fragment>
      <Particle />
      <div className="container" style={{ height: "100vh" }}>
        <div className="row h-100">
          <div className="col-lg-12 col-md-12 m-auto text-center align-items-center">
            <div className="row h-100">
              <div className="col-lg-12 col-md-12 m-auto text-center ">
                <img
                  src={process.env.PUBLIC_URL + "images/logo.png"}
                  alt="logo images"
                  className="img-fluid"
                  width="150px"
                />
              </div>
              <div className="col-lg-12 col-md-12 m-auto text-center myt-2">
                <h1 className="text-warning">WE'RE SO CLOSE</h1>
              </div>
              <div className="col-lg-12 col-md-12 m-auto text-center my-4">
                <h3 className="text-white">We love you all</h3>
                <h3 className="text-white">Valentine Surprise Soon</h3>
              </div>
              <div className="col-lg-12 col-md-12 m-auto text-center">
                <img
                  src={process.env.PUBLIC_URL + "images/comingsoon.png"}
                  alt="logo images"
                  className="img-fluid"
                  width="500px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ComingSoon;
