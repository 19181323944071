import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/scss/style.scss';
// import { store, persistor } from './connectors/manageLogin/configureStore';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import ReactGA from 'react-ga';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure({
  ...config,
  Analytics: {
    disabled: false,
  },
});

(function initializeReactGA() {
  ReactGA.initialize('UA-209151953-4');
  ReactGA.pageview('/');
})();
ReactDOM.render(
  // <PersistGate loading={null} persistor={persistor}>
  <App />,
  // </PersistGate>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
