import { Avatar } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SerumTooltip from "../Tooltip";

const NFTCard = ({
  data,
  isTChamber = false,
  isFromModal = false,
  handleCloseParent,
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleSelectNft = (data) => {
    if (isFromModal) {
      handleCloseParent(data);
    }
  };

  const onImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <>
      <Card className="nft-card h-100">
        <div className="position-relative">
          <Avatar
            src={process.env.PUBLIC_URL + data.img}
            onClick={() => {
              handleSelectNft(data);
            }}
            onLoad={onImageLoaded}
            className="card-img img img-fluid border-radius-0"
          />
          {!loaded && (
            <div className="image-container-overlay">
              <Spinner animation="border" variant="warning" />
            </div>
          )}
        </div>
        {isTChamber === false && (
          <Card.Body>
            <Card.Title>{data.title}</Card.Title>
            <Card.Text className={!isFromModal&&"mb-5"}>
              {data.description}
              {data.isTransformed === false && !isFromModal && (
                <Row className="nft-card-icons">
                  <Col>
                    <SerumTooltip title="Check Avaliable Serums">
                      <Link to={`/serums/${data.tokenID}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "images/icons/injection_check.png"
                          }
                          alt="nft"
                          className="width-60"
                        />
                      </Link>
                    </SerumTooltip>
                  </Col>
                  <Col className="text-end">
                    <SerumTooltip title="Transform This NFT">
                      <Link
                        to={{
                          pathname: `/t-chamber/${data.tokenID}`,
                          state: {
                            data: data,
                          },
                        }}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "images/icons/full.png"}
                          alt="nft"
                          className="width-60"
                        />
                      </Link>
                    </SerumTooltip>
                  </Col>
                </Row>
              )}
            </Card.Text>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default NFTCard;
