import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";

const BuyModal = ({ show, handleCloseParent, handleBuyParent, data}) => {
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  const handleYes = () => {
    setShowModal(false);
    handleBuyParent();
  }

  return (
    <Fragment>
      <Modal
        show={showModal}
        onHide={handleClose}
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{data.price===0?"Get":"Buy"} {data.title} Serum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col>{data.title}  <br/>Are you sure you want to {data.price===0?"Get":"Buy"} it?</Col>
           {/*<Col>{data.title} serum cost {data.price} <br/>Are you sure you want to Buy it?</Col> */}
            </Row>
        </Modal.Body>
        <Modal.Footer>
    <button className="btn"  onClick={handleClose}>No</button>
    <button className="btn" onClick={handleYes}>Yes</button>
  </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default BuyModal;
