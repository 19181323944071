export const SerumClassify = {
  Generic: 1,
  PerCollection: 2,
  PerCard: 3,
};
export const SerumStatus = {
  Empty: 1,
  Full: 2,
};
export const NFTsCollectionMapping = {
  "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8":
    "@Pudgy_Penguins #pudgy_penguins",
  "0x1a92f7381b9f03921564a437210bb9396471050c": "@coolcatsnft #CoolCatsNFT",
  "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e": "@doodles #Doodles",
  "0xCcc441ac31f02cD96C153DB6fd5Fe0a2F4e6A68d": "@FLUF_World #FLUFWORLD",
  "0x495f947276749ce646f68ac8c248420045cb7b5e": "@Chubbicorns #chubbicorns",
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d":
    "@BoredApeYC #BoredApeYachtClub #BoredApeYC",
};

export const collections = [
  {
    address: "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
    name: "Pudgy Penguins",
    image:
      "https://lh3.googleusercontent.com/bcCd1TfusKK6wWjmshwmizmY9j7An3pp9kxopMxfIt-_I8WFnSIK-5gevOduoYK4Qpq2e3DyXgROKNfkP396W5ViEYXhxoyAZG3s_vY=s130",
    openSeaLink: "https://opensea.io/collection/pudgypenguins",
  },
  {
    address: "0x1a92f7381b9f03921564a437210bb9396471050c",
    name: "Cool Cats NFT",
    image:
      "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s130",
    openSeaLink: "https://opensea.io/collection/cool-cats-nft",
  },
  {
    address: "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e",
    name: "Doodles",
    image:
      "https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s130",
    openSeaLink: "https://opensea.io/collection/doodles-official",
  },
  {
    address: "0xCcc441ac31f02cD96C153DB6fd5Fe0a2F4e6A68d",
    name: "FLUF World",
    image:
      "https://lh3.googleusercontent.com/6A_zAw2qtq91-5x8yuJUwuV6ttgD2G__RWVApFWfUcH8Qb8oLhW3uP9MkonFAaHRnE4XodwYHCgESxQ3xdQlQrjTros61gPaG3qf2nQ=s130",
    openSeaLink: "https://opensea.io/collection/fluf-world",
  },
  {
    address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
    name: "chubbicorns",
    image:
      "https://lh3.googleusercontent.com/pQN7zkxIaVtVhQOvtmcQka_wAVTgT3KWd_UaM_jVjwPToWLRaG3lh3Ew_fzYMc1h2ifXiWnSbGvaBp1R7JNgBaYNbeqTH5VfmQRfYbI=s130",
    openSeaLink: "https://opensea.io/collection/chubbicorns",
  },
  {
    address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
    name: "Bored Ape Yacht Club",
    image:
      "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130",
    openSeaLink: "https://opensea.io/collection/boredapeyachtclub",
  },
  {
    address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
    name: "Mutant Ape Yacht Club",
    image:
      "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s130",
    openSeaLink: "https://opensea.io/collection/mutant-ape-yacht-club",
  },
  {
    address: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
    name: "Bored Ape Kennel Club",
    image:
      "https://lh3.googleusercontent.com/l1wZXP2hHFUQ3turU5VQ9PpgVVasyQ79-ChvCgjoU5xKkBA50OGoJqKZeMOR-qLrzqwIfd1HpYmiv23JWm0EZ14owiPYaufqzmj1=s130",
    openSeaLink: "https://opensea.io/collection/bored-ape-kennel-club",
  },
  {
    address: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
    name: "World of Woman",
    image:
      "https://lh3.googleusercontent.com/7rQxqp2cAN4J-pFJ6A22Ncb_tm2j6Lz61zXMi9bNJbmAk8PheVXcL4zVIZptVQ8_owbOJAiYuhSbn0vtjwcE4Jg7FQqDGwZTndd-_A=s130",
    openSeaLink: "https://opensea.io/collection/world-of-women-nft",
  },
  {
    address: "0xf3E6DbBE461C6fa492CeA7Cb1f5C5eA660EB1B47",
    name: "Fame Lady Squad",
    image:
      "https://lh3.googleusercontent.com/1SRTUoLllhzL7ozwAe-P0HonTuEONQrVKxs444jfkjF7SrMI-Mzl_ilIO7CmZqfnPOcqcoApNHcnTsLjufLhJ9DMVB6GOuRwycJUcg=s130",
    openSeaLink: "https://opensea.io/collection/fameladysquad",
  },
  {
    address: "0x123b30E25973FeCd8354dd5f41Cc45A3065eF88C",
    name: "alien frens",
    image:
      "https://lh3.googleusercontent.com/_zidXBb2QsMBD6OYdjED63tczeXDUr1ah7zvhSSLHQjU4BF-H-lUexkLJ76_ahmbkkItEiH738jVPG88DOFVdt4GX377cvNNgCyzFT4=s130",
    openSeaLink: "https://opensea.io/collection/alienfrensnft",
  },
  {
    address: "0x1a2F71468F656E97c2F86541E57189F59951efe7",
    name: "CryptoMories",
    image:
      "https://lh3.googleusercontent.com/vb1frYDXLe4AHtDQORgr1O1EODOkq31xePpKvgsN3uE5wopK0M8rBYECcDbvTkOpx5Y-9x1TQIP61ae4wI9lq9qwe-wdSUsXywlw=s130",
    openSeaLink: "https://opensea.io/collection/cryptomories",
  },
  {
    address: "0x18c7766A10df15Df8c971f6e8c1D2bbA7c7A410b",
    name: "The Vogu Collective",
    image:
      "https://lh3.googleusercontent.com/4kPtQ8iep2Sw8MEV8TLfGuDsX0ql5pfniHZZGpwx3jp9D2ZRCE0coFxagGrhFH0sfVUezN1O-aRy9SZDong-N0RNsHgCg2rvX7LSJw=s130",
    openSeaLink: "https://opensea.io/collection/vogu",
  },
  {
    address: "0x11450058d796B02EB53e65374be59cFf65d3FE7f",
    name: "THE SHIBOSHIS",
    image:
      "https://lh3.googleusercontent.com/9G_SbzGpWnId_ouP-rsTfv1UIeSaaayelyZVyo7eVETYp2uDXDXeH82Yvw4AchRHuKSrixHhGQ7ri5UUepQLaK9BpwhMH1yjImnXHA=s130",
    openSeaLink: "https://opensea.io/collection/theshiboshis",
  },
  {
    address: "0x1981CC36b59cffdd24B01CC5d698daa75e367e04",
    name: "Crypto.Chicks",
    image:
      "https://lh3.googleusercontent.com/zEO0YjgOvjan3mBd37uSUP5hHY0dlY8M__ocpsWNEA6dJ2LcC1gkdA48KvOOrxboLKDuS631p99EBeRpnj0pzd69kABLjGFZUeHRFQ=s130",
    openSeaLink: "https://opensea.io/collection/the-crypto-chicks",
  },
  {
    address: "0xC2C747E0F7004F9E8817Db2ca4997657a7746928",
    name: "The Hashmasks",
    image:
      "https://lh3.googleusercontent.com/6X867ZmCsuYcjHpx-nmNkXeHaDFd2m-EDEEkExVLKETphkfcrpRJOyzFxRQlc-29J0e-9mB9uDGze0O9yracSA9ibnQm2sIq5i2Yuw=s130",
    openSeaLink: "https://opensea.io/collection/hashmasks",
  },
  {
    address: "0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D",
    name: "0N1 Force",
    image:
      "https://lh3.googleusercontent.com/7gOej3SUvqALR-qkqL_ApAt97SpUKQOZQe88p8jPjeiDDcqITesbAdsLcWlsIg8oh7SRrTpUPfPlm12lb4xDahgP2h32pQQYCsuOM_s=s130",
    openSeaLink: "https://opensea.io/collection/0n1-force",
  },
  {
    address: "0xc92cedDfb8dd984A89fb494c376f9A48b999aAFc",
    name: "Creature World",
    image:
      "https://lh3.googleusercontent.com/PkMdNUr4UsEq5RLvAm721Ja5QGAijunAVDMz0hfRuyHQn853TRQzKL4aTFBgWPoy-nWlYA3hXzAm7flCmKn7Z9p2HN0GJxAtAixB4g=s130",
    openSeaLink: "https://opensea.io/collection/creatureworld",
  },
  {
    address: "0x2acAb3DEa77832C09420663b0E1cB386031bA17B",
    name: "DeadFellaz",
    image:
      "https://lh3.googleusercontent.com/seJEwLWJP3RAXrxboeG11qbc_MYrxwVrsxGH0s0qxvF68hefOjf5qrPSKkIknUTYzfvinOUPWbYBdM8VEtGEE980Qv2ti_GGd86OWQ=s130",
    openSeaLink: "https://opensea.io/collection/deadfellaz",
  },
  {
    address: "0xa5C0Bd78D1667c13BFB403E2a3336871396713c5",
    name: "Coolman's Universe",
    image:
      "https://lh3.googleusercontent.com/E2qCzhIiOS7mfw-vkPU3ROBTbNMeSaC47DLYXd9qiV275KGgciNbbKFgpffwOEpTTJ9JHHztmv1nnh6bOo0KpoFueK4EitHJzsqX=s130",
    openSeaLink: "https://opensea.io/collection/coolmans-universe",
  },
  {
    address: "0x8943C7bAC1914C9A7ABa750Bf2B6B09Fd21037E0",
    name: "Lazy Lions",
    image:
      "https://lh3.googleusercontent.com/62sZVScyxqlvpNh6J0JhiudQFegqTvqx_11YZxWJnwZqbLbvYklFjtVHWKwDZkLxH3acnJMUEsZhmUqTPcB_NRk9uv0RR0fw61_B=s130",
    openSeaLink: "https://opensea.io/collection/lazy-lions",
  },
  {
    address: "0xEdB61f74B0d09B2558F1eeb79B247c1F363Ae452",
    name: "Gutter Cat Gang",
    image:
      "https://lh3.googleusercontent.com/gjOExI2qNNJuv6fo4hmHamRzDMRLkYPFQtK4d67GE3bgdVJCyZngUwY_h6qp9244pp7l_pF2JBHtOJ8cLgzFbulxMK-KqlZnu9E1sg=s130",
    openSeaLink: "https://opensea.io/collection/guttercatgang",
  },
  {
    address: "0x6E9DA81ce622fB65ABf6a8d8040e460fF2543Add",
    name: "Gutter Dogs",
    image:
      "https://lh3.googleusercontent.com/vNgijw2b0ybGsKbVw5210hQhv42uFiX2r0IMnD0GbyX-Tg-YI7F09_QnIWSiHdPIKfi4ANzH204I1SgKkBz_uMO0njDaVfsXivIPYMU=s130",
    openSeaLink: "https://opensea.io/collection/gutterdogs",
  },
  {
    address: "0x3Fe1a4c1481c8351E91B64D5c398b159dE07cbc5",
    name: "Sup Ducks",
    image:
      "https://lh3.googleusercontent.com/WNd3NNCiOAkD3rUNCau9qXDPQsnH3ZA8PcDMhlsfLnK6J1vSNpJnPFCjA-_l03LbCxwAYC1Mj8IcRQch7YMTDLnn_c-jAQ03RWRdEw=s130",
    openSeaLink: "https://opensea.io/collection/supducks",
  },
  {
    address: "0x6dc6001535e15b9def7b0f6A20a2111dFA9454E2",
    name: "MetaHero Universe",
    image:
      "https://lh3.googleusercontent.com/cyJeHA0Oo-KveczexdimAxDHTCtmvApkn2SWFhq_QmU4CotxW1uCRp-rBydovapL65kG5geRCrGZts74o3eZS9VONQ6549A4DIMCjg=s130",
    openSeaLink: "https://opensea.io/collection/metahero-generative",
  },
  {
    address: "0xF1268733C6FB05EF6bE9cF23d24436Dcd6E0B35E",
    name: "Desperate ApeWives",
    image:
      "https://lh3.googleusercontent.com/6yDCMzPIcptR38LjsP-QyMvan-6rO8RSy_tHtwzXVpppTEuFXbgxTIjt5TzlP94Ql30bUeStPSrJQx0Ca1xd48K5PCiK3v-CvFLsBg=s130",
    openSeaLink: "https://opensea.io/collection/desperate-ape-wives",
  },
  {
    address: "0x6069a6C32cf691F5982FEbAe4fAf8a6f3AB2F0F6",
    name: "Bulls on the Block Evo",
    image:
      "https://lh3.googleusercontent.com/z7yu67wfGPd1lmThU6LB8tZk7u6mZGlgS1yBebhEl-O67HLSIfrfzbrzfj1bVfZMsnqdiRK-Dp9VjXs873Pl_J7COB6beHOXXJzR=s130",
    openSeaLink: "https://opensea.io/collection/bullsontheblock-evo",
  },
  {
    address: "0xEf0182dc0574cd5874494a120750FD222FdB909a",
    name: "Rumble Kong League",
    image:
      "https://lh3.googleusercontent.com/x18rNFBg9leLL9TtHkhhiC8cwIurh1UhMKU6TL_JMbGyUsY8MTMhyPiz8Nz7VRJHShEgIQlCP070UB9gGWvJ05ST7IclovIWnwAUww=s130",
    openSeaLink: "https://opensea.io/collection/rumble-kong-league",
  },
  {
    address: "0x1CB1A5e65610AEFF2551A50f76a87a7d3fB649C6",
    name: "CrypToadz",
    image:
      "https://lh3.googleusercontent.com/iofetZEyiEIGcNyJKpbOafb_efJyeo7QOYnTog8qcQJhqoBU-Vu9l3lXidZhXOAdu6dj4fzWW6BZDU5vLseC-K03rMMu-_j2LvwcbHo=s130",
    openSeaLink: "https://opensea.io/collection/cryptoadz-by-gremplin",
  },
  {
    address: "0xF4ee95274741437636e748DdAc70818B4ED7d043",
    name: "The Doge Pound",
    image:
      "https://lh3.googleusercontent.com/uNkBbVvg7wyRjspsooFtYYbRHFHFISntxwijLRskjGhplP8A3Iz_4ApLU_yLp9BW-Ai0yUWC50A138IGUkII55QWRT7g12qmSRTlbA=s130",
    openSeaLink: "https://opensea.io/collection/the-doge-pound",
  },
  {
    address: "0x7AB2352b1D2e185560494D5e577F9D3c238b78C5",
    name: "Adam Bomb Squad",
    image:
      "https://lh3.googleusercontent.com/AFLfluhz2BbvfAUDmTdZzZKvF-wETqzYFZUefPJIw9tO9uXiqftaznKxpgUobXFQqpmG5n2tgAvjbSycubZZ7E89D4K8QbzIFtGWCkY=s130",
    openSeaLink: "https://opensea.io/collection/adam-bomb-squad",
  },
];

export const categories = [
  { fLevel: "General", sLevel: [{ name: "General", tLevel: [{name:"General"}] }] },
  {
    fLevel: "Events",
    sLevel: [
      { name: "DGH", tLevel: [{name:"General"}] },
      { name: "Valentine", tLevel: [{name:"General"}] },
    ],
  },
  {
    fLevel: "Sports",
    sLevel: [
      { name: "NBA", tLevel: [{name:"General"}] },,
    ],
  },  {
    fLevel: "Music",
    sLevel: [
      { name: "Pop", tLevel: [{name:"General"}] },,
    ],
  },
];
