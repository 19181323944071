import { StaticJsonRpcProvider} from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import React, { useEffect } from "react";

import { useUserProvider } from "../hooks";

import { NETWORKS } from "../constants";

const targetNetwork = NETWORKS.mumbai;
const localProviderUrl = targetNetwork.rpcUrl;
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
const localProvider = new StaticJsonRpcProvider(localProviderUrlFromEnv);

export default localProvider;
