import ABI from '../../contracts/contractABI';
import memoizeContracts from './loadContract';
import {
  TRANSFORMED_NFT_CONTRACT, SERUM_CONTRACT,
  RINKEBY_SERUM_CONTRACT, RINKEBY_TRANSFORMED_CONTRACT,
  POLYGON_SERUM_CONTRACT, POLYGON_TRANSFORMED_NFT_CONTRACT,
  ETHER_SERUM_CONTRACT, ETHER_TRANSFORMED_CONTRACT

} from '../../constants';
const axios = require('axios');

const nftTransactions = (apiUrl, apiKey, userAddress) => {
  try {
    return axios.get(apiUrl, {
      params: {
        module: 'account',
        action: 'tokennfttx',
        address: userAddress,
        startblock: 0,
        endblock: 999999999,
        sort: 'endblock',
        apikey: apiKey,
      },
    });
  } catch (error) {
    //console.log("error: ");
  }
};

const validateURI = (tokenURI) => {
  // validation #0 url is as we expected
  let handlingStringUrlRegex = /^https:\/\/.+/;
  if (handlingStringUrlRegex.test(tokenURI)) {
    return tokenURI;
  }

  // validation #1 expected url (ipfs://.....)
  handlingStringUrlRegex = /^ipfs:\/\/.+/;
  if (handlingStringUrlRegex.test(tokenURI)) {
    return makeGatewayURL(tokenURI);
  }

  // validation #3 expected url (axderfsd.json)
  return 'https://dweb.link/ipfs/' + tokenURI;
};

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, 'https://dweb.link/ipfs/');
}

const loadUserNFTData = async (chainMetaData, userAddress) => {
  const polygonTestnetSerumContract = SERUM_CONTRACT.toLowerCase();
  const polygonTestnetTransformedContract = TRANSFORMED_NFT_CONTRACT.toLowerCase();

  const rinkebySerumContract = RINKEBY_SERUM_CONTRACT.toLowerCase();
  const rinkebyTransformedContract = RINKEBY_TRANSFORMED_CONTRACT.toLowerCase();

  const polygonMainnetSerumContract = POLYGON_SERUM_CONTRACT.toLowerCase();
  const polygonMainnetTransformedContract = POLYGON_TRANSFORMED_NFT_CONTRACT.toLowerCase();

  const ethSerumContract = ETHER_SERUM_CONTRACT.toLowerCase();
  const ethTransformedContract = ETHER_TRANSFORMED_CONTRACT.toLowerCase();

  let incomingNfts = []; // addresses for incoming nfts

  const res = await nftTransactions(
    chainMetaData.apiUrl,
    chainMetaData.apiKey,
    userAddress
  );

  let inOutTransactions = res.data.result;
  if (chainMetaData.tag === 'M-POLYGON') {
    inOutTransactions = inOutTransactions.filter(
      (transaction) => transaction.contractAddress !== polygonTestnetSerumContract
    );
  } else if (chainMetaData.tag === 'R-ETH') {
    inOutTransactions = inOutTransactions.filter(
      (transaction) => transaction.contractAddress !== rinkebySerumContract
    );
  } else if (chainMetaData.tag == 'POLYGON') {
    inOutTransactions = inOutTransactions.filter(
      (transaction) => transaction.contractAddress !== polygonMainnetSerumContract
    );
  } else if (chainMetaData.tag == 'ETH') {
    inOutTransactions = inOutTransactions.filter(
      (transaction) => transaction.contractAddress !== ethSerumContract
    );
  }
  // console.log(inOutTransactions);
  let tokens = new Map();

  userAddress = userAddress.toLowerCase();

  inOutTransactions.forEach(function (inOutTransaction) {
    const key = `${inOutTransaction.contractAddress},${inOutTransaction.tokenID}`;

    // by default set as sell transaction
    let buyORsell = 'sell';

    if (inOutTransaction.to == userAddress) {
      buyORsell = 'buy';
    }

    tokens.set(key, buyORsell);
  });

  for (const [key, value] of tokens.entries()) {
    if (value === 'buy') {
      incomingNfts.push(key.split(','));
    }
  }

  //incomingNfts = incomingNfts.slice(50, 100);
  let tokenMetadata = incomingNfts.map((Meta) => {
    //console.log(chainMetaData.tag, Meta[0], Meta[1]);
    return getTokenUri(Meta, chainMetaData.provider);
  });

  let cards = (await Promise.all(tokenMetadata)).map(async (token) => {
    try {
      const validatedTokenURI = validateURI(token.tokenURI);
      //console.log('validated tokenURI', validatedTokenURI);
      const NFTMetaData = await (await fetch(validatedTokenURI)).json();
      //console.log('fetched metadata', NFTMetaData);
      if (!NFTMetaData.image) {
        console.log('there is no image exist!');
        return {};
      }

      const img = makeGatewayURL(NFTMetaData.image);
      //console.log(img);
      const isTransformed =
        token.contractAddress === polygonTestnetTransformedContract ||
        token.contractAddress === rinkebyTransformedContract ||
        token.contractAddress === polygonMainnetTransformedContract ||
        token.contractAddress === ethTransformedContract;

      return {
        img,
        description: NFTMetaData.description,
        title: NFTMetaData.name,
        contractAddress: token.contractAddress,
        tokenID: token.tokenID,
        networkChainId: chainMetaData.chainID,
        isTransformed,
      };
    } catch (error) {
      //console.log('Error in fetching data', token.tokenURI);
      return {};
    }
  });
  cards = (await Promise.all(cards)).filter((x) => Object.keys(x).length !== 0);
  return cards;
};

const getTokenUri = async (Meta, provider) => {
  // 0 -> contract , 1 -> toke-id
  //const cont = new ethers.Contract(Meta[0], ABI, provider);
  const cont = memoizeContracts(Meta[0], ABI, provider);
  const tokenURI = await cont.tokenURI(Meta[1]);
  return { tokenURI, contractAddress: Meta[0], tokenID: Meta[1] };
};

export default loadUserNFTData;
