import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useUserAddress } from 'eth-hooks';
import Layout from '../layout';
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';
import NFTCard from '../components/nftCard';
import PageTitle from '../components/pageTitle';
import CardLoader from '../components/cardLoader';
import { useUserProvider } from '../connectors/hooks';
import loadUserNFTData from '../connectors/helpers/loadUserNFTs/loadUserNFTData';
import networksMetaData from '../connectors/helpers/loadUserNFTs/networksMetaData';
import ReactGA from 'react-ga';

const Nfts = () => {
  const [injectedProvider, setInjectedProvider] = useState();
  const userProvider = useUserProvider(injectedProvider, undefined);
  const currentWallet = useUserAddress(userProvider);
  // const signer = userProvider?.getSigner();

  const [allMyNfts, setAllMyNFts] = useState();
  const [NotTransformedNfts, setNotTransformedNfts] = useState();
  const [transformedNfts, setTransformedNfts] = useState();
  const [loader, setLoader] = useState(true);

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    ReactGA.pageview('NFT Screen');
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getCurrentUserTokens = useCallback(async () => {
    if (currentWallet) {
      try {
        console.log(window.ethereum);
        let network = await userProvider.getNetwork();
        let chainId = network.chainId;
        console.log('chainId', chainId);
        // let userAddress = await signer.getAddress();
        // console.log('userAddress', userAddress);
        let chainMetaData = networksMetaData[chainId];
        console.log('network metadata', chainMetaData);
        chainMetaData.provider = userProvider;
        return await loadUserNFTData(chainMetaData, currentWallet);
      } catch (error) {
        console.log('can not detect the signer');
        return [];
      }
    }

    return undefined;
  }, [currentWallet, userProvider]);

  useEffect(() => {
    async function fetchUserData() {
      setAllMyNFts(await getCurrentUserTokens());
    }
    if (!allMyNfts) fetchUserData();
  }, [getCurrentUserTokens]);

  useEffect(() => {
    if (allMyNfts) {
      console.log(allMyNfts);
      setNotTransformedNfts(allMyNfts.filter((ntf) => !ntf.isTransformed));
      setTransformedNfts(allMyNfts.filter((ntf) => ntf.isTransformed));
      setLoader(false);
    }
  }, [allMyNfts]);

  /*const allMyNfts = [
    {
      id: 1,
      img: "images/nfts/nft1.png",
      title: "NFT1",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
    {
      id: 2,
      img: "images/nfts/nft2.jpg",
      title: "NFT2",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: false,
    },
    {
      id: 3,
      img: "images/nfts/nft3.gif",
      title: "NFT3",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: false,
    },
    {
      id: 4,
      img: "images/nfts/nft4.jpg",
      title: "NFT4",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
    {
      id: 5,
      img: "images/nfts/nft5.png",
      title: "NFT5",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: false,
    },
    {
      id: 6,
      img: "images/nfts/nft6.png",
      title: "NFT6",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
    {
      id: 7,
      img: "images/nfts/nft7.png",
      title: "NFT7",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
    {
      id: 8,
      img: "images/nfts/nft8.jpg",
      title: "NFT8",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: false,
    },
    {
      id: 9,
      img: "images/nfts/nft9.png",
      title: "NFT9",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
    {
      id: 10,
      img: "images/nfts/nft10.png",
      title: "NFT10",
      description:
        "What happens when a group of apes working in a bakery gets pissed on by birds putting out a fire set by potatoes? Caked Apes happen. They are 8888 unique orphaned NFTs that need a home.",
      isTransformed: true,
    },
  ];*/
  return (
    <Fragment>
      <Layout>
        <PageTitle title='NFTS' />
        <Container className='my-5'>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='mynfts'
                id='uncontrolled-tab-example'
                className='mb-3 justify-content-center horizontal-tabs'
              >
                <Tab eventKey='mynfts' title='My NFTs'>
                  {loader ? (
                    <Row>
                      <Col lg={3} md={4} sm={12}>
                        <CardLoader />
                      </Col>
                      <Col lg={3} md={4} sm={12}>
                        <CardLoader />
                      </Col>
                      <Col lg={3} md={4} sm={12}>
                        <CardLoader />
                      </Col>
                      <Col lg={3} md={4} sm={12}>
                        <CardLoader />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      {NotTransformedNfts && NotTransformedNfts.length > 0 ? (
                        NotTransformedNfts &&
                        NotTransformedNfts.map((NFT, index) => (
                          <Col
                            key={index}
                            lg={3}
                            md={4}
                            sm={12}
                            className='mb-4'
                          >
                            <NFTCard data={NFT} />
                          </Col>
                        ))
                      ) : (
                        <Col>
                          <h4 className='no-data text-center py-5'>
                            No Data to Show
                          </h4>
                        </Col>
                      )}
                    </Row>
                  )}
                </Tab>
                <Tab eventKey='mytransformednfts' title='My Transformed NFTs'>
                  <Row>
                    {transformedNfts &&
                      transformedNfts.map((NFT, index) => (
                        <Col key={index} lg={3} md={3} sm={12} className='mb-4'>
                          <NFTCard data={NFT} />
                        </Col>
                      ))}
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Layout>
    </Fragment>
  );
};

export default Nfts;
