import { formatEther } from '@ethersproject/units';
import React, { useState } from 'react';
import { useBalance } from '../hooks';

export default function Balance(props) {
  const [dollarMode, setDollarMode] = useState(true);

  // const [listening, setListening] = useState(false);

  const balance = useBalance(props.provider, props.address);
  console.log("bakance", balance)
  let floatBalance = parseFloat('0.00');

  let usingBalance = balance;

  if (typeof props.balance !== 'undefined') {
    usingBalance = props.balance;
  }
  if (typeof props.value !== 'undefined') {
    usingBalance = props.value;
  }

  if (usingBalance) {
    const etherBalance = formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = parseFloat(etherBalance);
  }

  let displayBalance = floatBalance.toFixed(4);
  console.log("displayBalance", displayBalance)
  localStorage.setItem(
    'displayBalance',
    displayBalance
  );
  const price = props.price || props.dollarMultiplier;

  if (price && dollarMode) {
    displayBalance = '$' + (floatBalance * price).toFixed(2);
  }

  return (
    <span
      style={{
        verticalAlign: 'middle',
        fontSize: props.size ? props.size : 24,
        padding: 8,
        cursor: 'pointer',
      }}
      onClick={() => {
        setDollarMode(!dollarMode);
      }}
    >
      {/*displayBalance*/}
    </span>
  );
}
