// API token for nft.storage. Please sign up and replace this with your own key from https://nft.storage/manage/
export const NFT_STORAGE_KEY = process.env.REACT_APP_NFT_NFT_STORAGE_KEY;

// Infura project ID. If you're building a real app based on this, please replace with your own from https://infura.io/dashboard/ethereum
export const INFURA_ID = process.env.REACT_APP_NFT_INFURA_ID;
// "69f75bb89ba74822b512b464ef1495c9";

// Key for Etherscan.io. If you're building a real app based on this, please replace with your own from https://etherscan.io/myapikey
export const ETHERSCAN_KEY = process.env.REACT_APP_NFT_ETHERSCAN_KEY;
// "6EAGC2DEG6PP3M1GP1I86QJPQAUE4ESW1W";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = process.env.REACT_APP_NFT_BLOCKNATIVE_DAPPID;
// "0b58206a-f3c0-4701-a62f-73c7243e8c77";

export const DEFAULT_CONTRACT_NAME = 'PaybeatsNFT';

export const PUBLIC_ADDRESS = process.env.REACT_APP_PUBLIC_ADDRESS;
// export const PRIVATE_KEY =
//   '4ff4e4322994486d89f53839f19fa7be7488745c3b580bf8f799eadb6107937d';

export const IPFS_STORAGE_URL = process.env.REACT_APP_IPFS_STORAGE_URL;

export const NFT_OLD_ADDRESS = process.env.REACT_APP_OLD_ADDRESS;
//export const NFT_TRACKS_ADDRESS = "0x626467BDfAd8c23fD084113aF0D3A42687dAF82B";
export const PLAY_VESTING_CONTRACT =
  process.env.REACT_APP_PLAY_VESTING_CONTRACT;

// Mumbai
export const SERUM_CONTRACT = process.env.REACT_APP_SERUM_CONTRACT;
export const TRANSFORMED_NFT_CONTRACT = process.env.REACT_APP_TRANSFORMED_NFT_CONTRACT;

// Rinkeby
export const RINKEBY_SERUM_CONTRACT = process.env.REACT_APP_RINKEBY_SERUM_CONTRACT;
export const RINKEBY_TRANSFORMED_CONTRACT = process.env.REACT_APP_RINKEBY_TRANSFORMED_CONTRACT;

// Polygon
export const POLYGON_SERUM_CONTRACT = process.env.REACT_APP_POLYGON_SERUM_CONTRACT;
export const POLYGON_TRANSFORMED_NFT_CONTRACT = process.env.REACT_APP_POLYGON_TRANSFORMED_NFT_CONTRACT;
  
// Ether
export const ETHER_SERUM_CONTRACT = process.env.REACT_APP_ETHER_SERUM_CONTRACT;
export const ETHER_TRANSFORMED_CONTRACT = process.env.REACT_APP_ETHER_TRANSFORMED_CONTRACT;
  
export const DEPLOYER = process.env.REACT_APP_DEPLOYER;

export const ETH_NAME = process.env.REACT_APP_ETH_NETWORK;
export const POL_NAME = process.env.REACT_APP_POL_NETWORK;
/*
export const NFT_OLD_URI =
  "bafyreigoro5hqvsni2m4jxhjizzpb5jmfwrjagod2bs74z4xxkyvzt6mpq/metadata.json";
export const NFT_TRACKS_URI =
  "bafyreigqf3qqwemftowxadvc43cal7lcjuzkl7hkqdtphsh6hq5j5m76uy/metadata.json";
*/

export const NETWORKS = {
  localhost: {
    name: 'localhost',
    color: '#666666',
    chainId: 31337,
    blockExplorer: '',
    rpcUrl: 'http://' + window.location.hostname + ':8545',
  },
  mainnet: {
    name: 'mainnet',
    color: '#ff8b9e',
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    blockExplorer: 'https://etherscan.io/',
  },
  kovan: {
    name: 'kovan',
    color: '#7003DD',
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: 'https://kovan.etherscan.io/',
    faucet: 'https://gitter.im/kovan-testnet/faucet', // https://faucet.kovan.network/
  },
  rinkeby: {
    name: 'rinkeby',
    color: '#e0d068',
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: 'https://faucet.rinkeby.io/',
    blockExplorer: 'https://rinkeby.etherscan.io/',
  },
  ropsten: {
    name: 'ropsten',
    color: '#F60D09',
    chainId: 3,
    faucet: 'https://faucet.ropsten.be/',
    blockExplorer: 'https://ropsten.etherscan.io/',
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: 'goerli',
    color: '#0975F6',
    chainId: 5,
    faucet: 'https://goerli-faucet.slock.it/',
    blockExplorer: 'https://goerli.etherscan.io/',
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: 'xdai',
    color: '#48a9a6',
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: 'https://dai.poa.network',
    faucet: 'https://xdai-faucet.top/',
    blockExplorer: 'https://blockscout.com/poa/xdai/',
  },
  matic: {
    name: 'matic',
    color: '#2bbdf7',
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: 'https://rpc-mainnet.maticvigil.com',
    faucet: 'https://faucet.matic.network/',
    blockExplorer: 'https://explorer-mainnet.maticvigil.com//',
  },
  mumbai: {
    name: 'mumbai',
    color: '#92D9FA',
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com',
    faucet: 'https://faucet.matic.network/',
    blockExplorer: 'https://mumbai-explorer.matic.today/',
  },
  bsc_testnet: {
    name: 'Smart Chain - Testnet',
    color: '#FCD535',
    chainId: 97,
    rpcUrl: `https://data-seed-prebsc-1-s1.binance.org:8545`,
    faucet: 'https://testnet.binance.org/faucet-smart/',
    blockExplorer: 'https://testnet.bscscan.com/',
  },
  bsc_mainnet: {
    name: 'Smart Chain - Mainnet',
    color: '#FCD199',
    chainId: 56,
    rpcUrl: `https://bsc-dataseed.binance.org/`,
    blockExplorer: 'https://bscscan.com',
  },
};

// eslint-disable-next-line consistent-return
export const NETWORK = (chainId) => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};
