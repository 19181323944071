import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useUserAddress } from 'eth-hooks';
import { Col, Row } from 'react-bootstrap';
import NFTCard from '../components/nftCard';
import Layout from '../layout';
import Carousel from 'react-elastic-carousel';
import SerumCard from '../components/serumCard';
import NftModal from '../components/NftModal';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/pageTitle';
import { Web3Provider } from '@ethersproject/providers';
import useContractLoader from '../connectors/hooks/ContractLoader';
import useUserProvider from '../connectors/hooks/UserProvider';
import localProvider from '../connectors/helpers/Provider';
import { DEPLOYER } from '../connectors/constants';
import { SerumStatus, NFTsCollectionMapping } from '../Enums';
import ReactGA from 'react-ga';
import { Player } from 'video-react';
import { Auth, API } from 'aws-amplify';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import { ethers } from 'ethers';
import ShareToTwitterModal from '../components/shareToTwitter';
import Discord from 'discord.js';
import {
  TRANSFORMED_NFT_CONTRACT,
  RINKEBY_TRANSFORMED_CONTRACT,
} from '../connectors/constants';
import { toast as networkToast } from 'react-toastify';

function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, 'https://dweb.link/ipfs/');
}

async function fetchIPFSJSON(ipfsURI) {
  const resp = await fetch('https://dweb.link/ipfs/' + ipfsURI);
  return resp.json();
}

async function getNFTImage(tokenMetaUri) {
  const metadata = await fetchIPFSJSON(tokenMetaUri);

  if (metadata.image) {
    metadata.image = makeGatewayURL(metadata.image);
  }

  return metadata.image;
}

const TransformationChamber = (props) => {
  networkToast.configure({
    position: 'top-center',
    autoClose: false,
    draggable: false,
  });
  const [applicableSerums, setApplicableSerums] = useState();
  const [loader, setLoader] = useState();

  const [currentUser, setCurrentuser] = useState();

  let { nftId } = useParams();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 992, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const [nftData, setNftData] = useState();
  const [balance, setBalance] = useState();
  const [selectedMention, setSelectedMention] = useState();

  const [isLoaded, setIsLoaded] = useState(false);
  const [showNftsModal, setShowNftsModal] = useState();
  const [showTweeterModal, setShowTweeterModal] = useState();
  const [choosedNftId, setChoosedNftId] = useState(Number(nftId));
  const [transformedImg, setTransformedImg] = useState();
  const [isTransformLoading, setIsTransformLoading] = useState(false);
  const [injectedProvider, setInjectedProvider] = useState();
  const [SerumContract, setSerumContract] = useState();
  const userProvider = useUserProvider(injectedProvider, localProvider);

  const currentWallet = useUserAddress(userProvider);

  const selectedChainId =
    userProvider && userProvider._network && userProvider._network.chainId;

  const serumNftContracts = useContractLoader(
    userProvider,
    'serum',
    selectedChainId
  );

  // const signer = userProvider?.getSigner();

  const loadWeb3Modal = useCallback(async () => {
    setInjectedProvider(new Web3Provider(window.ethereum));
  }, [setInjectedProvider]);

  useEffect(() => {
    ReactGA.pageview('Transformation Chamber Screen');
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  const getMergedImage = async (nft, serum) => {
    //console.log('Herre');
    let balance = localStorage.getItem("displayBalance");
    //console.log("hello from tc", balance);
    if (balance <= serum.fee){
      window.alert("You don't have enough balance to apply the serum !");
    } else {
      setIsTransformLoading(true);
      try{ const resposne = await API.post('serumnftsapi', `/mergeImages`, {
        headers: { 'Content-Type': 'application/json' },
        body: {
          serumImg: serum.img,
          nftImg: nft.img,
          name: nft.title + ' ' + serum.title,
          description: nft.title + ' with ' + serum.description,
          originalName: nft.title,
          serumCat: serum.category,
          collectionName: nft.title,
          contractAddress: nft.contractAddress,
        },
      })
        .catch((error) => {
          console.log(error);
        })
        .then(async (response) => {
          console.log('Response', response);
          const metaUri = response.Data.replace(/^ipfs:\/\//, '');
  
          // setTransformedImg(process.env.PUBLIC_URL + "images/transformProcess.jpg"); // temporary image
  
          // const address = await signer.getAddress();
          const res = await SerumContract.transform(
            currentWallet,
            metaUri,
            nft.title + ' ' + serum.title,
            nft.contractAddress,
            nft.tokenID,
            serum.id,
            { value: ethers.utils.parseEther(serum.fee.toString()) }
          );
  
          /** Transaction Hash Is Returned Perform Remaining Actions  */
          if (res) {
            console.log('Transaction Confirmed New image is loading');
            const image = await getNFTImage(metaUri);
            console.log('image link', image);
            if (image) {
              setTransformedImg(image);
              console.log('New image is loaded');
              setIsTransformLoading(false);
              setShowTweeterModal(true);
              SendTweet(image);
              sendDiscordMessage(
                nft.title + ' ' + serum.title,
                image,
                'We have a new transformed NFT'
              );
            }
          }
        });
      }
      catch (error) {
        networkToast.error(
          <div>
            <p>⚠️ Server Error</p>
            <div>
              There is Error Please Refresh Page and Try again 
            </div>
          </div>,
          {
            toastId: 2,
          }
        );
        return [];
      }
    }
  };

  const getCurrentSerums = useCallback(
    async (contract) => {
      try {
        let currentSerums = [];
        const allTokens = await contract.getAllTokens();
        //const currentAddress = await signer.getAddress();

        for (var token of allTokens) {
          const emptyAddress = /^0x0+$/.test(token.owner);
          if (!emptyAddress) {
            const image = await getNFTImage(
              token.status == SerumStatus.Full ? token.fullUri : token.emptyUri
            );

            const tempSerum = {
              id: parseInt(token.id._hex.toString(), 16),
              img: image,
              description: token.description,
              status: parseInt(token.status._hex.toString(), 16),
              classification: parseInt(token.classify._hex.toString(), 16),
              isOwnedByUser: token.owner == currentWallet ? true : false,
              title: token.name,
              owner: token.owner,
              fee:
                parseInt(token.transformationFee._hex.toString(), 16) /
                  10 ** 18 || 0,
              category: token.category,
              price: parseInt(token.price._hex.toString(), 16) / 10 ** 18 || 0,
              collectionAddress: token.collectionAddress,
              cardNum: token.cardNum,
            };

            currentSerums.push(tempSerum);
          }
        }
        return currentSerums;
      } catch (error) {}
    },
    [currentWallet]
  );

  const loadNftData = useCallback(async () => {
    if (selectedChainId && [80001, 4, 1, 137].includes(selectedChainId)) {
      const Contract = serumNftContracts ? serumNftContracts['SerumNFT'] : '';
      Contract && setSerumContract(Contract);

      const getSerums = Contract && (await getCurrentSerums(Contract));
      const serumsData = getSerums && [...getSerums];

      if (!!serumsData) {
        const mySerums = serumsData.filter(
          (serum) => serum.isOwnedByUser && serum.status === SerumStatus.Full
        );
        const buyableSerums = serumsData.filter(
          (serum) => serum.owner === DEPLOYER
        );
        setApplicableSerums(mySerums.concat(buyableSerums));
      }
      !!serumsData && setLoader(false);
    }
  }, [getCurrentSerums, serumNftContracts, applicableSerums]);

  useEffect(() => {
    if (!applicableSerums) {
      loadNftData();
    }
  }, [loadNftData, applicableSerums]);

  useEffect(() => {
    if (props.location.state && props.location.state.data) {
      localStorage.setItem(
        'selectedNft',
        JSON.stringify(props.location.state.data)
      );
    }
    choosedNftId > 0 &&
      setNftData(JSON.parse(localStorage.getItem('selectedNft')));
    setIsLoaded(true);
    choosedNftId > 0 &&
      getCollectionMention(
        JSON.parse(localStorage.getItem('selectedNft')).contractAddress
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollectionMention = (nftAddress) => {
    const arr = Object.keys(NFTsCollectionMapping);
    const selectedIndex = arr.findIndex((e) => e === nftAddress);
    const selectedMention = NFTsCollectionMapping[arr[selectedIndex]];
    setSelectedMention(selectedMention);
  };

  const getSelectedNft = (data) => {
    setChoosedNftId(data.tokenID);
    setNftData(data);
    getCollectionMention(data.contractAddress);
    setShowNftsModal(false);
    console.log(data);
  };

  const SendTweet = async (image) => {
    await API.post('serumnftsapi', '/tweetImage', {
      headers: { 'Content-Type': 'application/json' },
      body: {
        status: `Fresh out of the ${
          [80001, 137].includes(selectedChainId) ? '#Polygon' : '#Ethereum'
        }  #NFT Transformation Chamber, Congratulations!
        ${process.env.REACT_APP_OPENSEA_LINK}${currentWallet}
        #NFTCommmunity  ${selectedMention?selectedMention:""} Get yours! `,
        img: image,
      },
    }).then((response) => {
      if (response.Success) {
      }
    });
  };

  const sendDiscordMessage = async (nftName, nftUrl, description) => {
    const webhookId = process.env.REACT_APP_SERUMS_NFT_WEBHOOK_ID;
    const webhookToken = process.env.REACT_APP_SERUMS_WEBHOOK_TOKEN;
    const webhookClient = new Discord.WebhookClient(webhookId, webhookToken);
    const embed = new Discord.MessageEmbed()
      .setTitle(nftName)
      .setColor('#0099ff')
      .setDescription(description)
      .setImage(nftUrl)
      .setTimestamp();
    webhookClient.send('', {
      username: 'serum-news',
      avatarURL: 'https://cdn-icons-png.flaticon.com/512/1042/1042680.png',
      embeds: [embed],
    });
    webhookClient.destroy();
  };

  return (
    <Fragment>
      <Layout>
        <PageTitle title='T Chamber' />
        {isLoaded && (
          <>
            {choosedNftId > 0 ? (
              <Row className='justify-content-center'>
                <Col
                  lg={3}
                  md={4}
                  sm={12}
                  onClick={() => {
                    setShowNftsModal(true);
                  }}
                >
                  <NFTCard data={nftData} isTChamber={true} />
                </Col>
              </Row>
            ) : (
              <Row className='justify-content-center mt-3 mb-5'>
                <Col lg={3} md={4} sm={12} className='text-center'>
                  <img
                    src={process.env.PUBLIC_URL + 'images/transformProcess.jpg'}
                    alt='logo images'
                    className='img-fluid'
                    onClick={() => {
                      setShowNftsModal(true);
                    }}
                  />
                </Col>
              </Row>
            )}
            {showNftsModal && (
              <NftModal
                show={showNftsModal}
                handleCloseParent={(selectedNft) => {
                  getSelectedNft(selectedNft);
                  setShowNftsModal(false);
                }}
              />
            )}
            <Row className='justify-content-center my-3'>
              <Col lg={3} md={4} sm={12} className='text-center'>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    'images/icons/Arrows_down_animated.gif'
                  }
                  alt='logo images'
                  className='img-fluid'
                  width='30px'
                />
              </Col>
            </Row>
            {choosedNftId > 0 ? (
              <Row className='justify-content-center my-3'>
                <Col lg={11} md={11} sm={12} className='mb-4'>
                  <Carousel breakPoints={breakPoints}>
                    {applicableSerums &&
                      applicableSerums.map((serum, index) =>
                        serum.classification == 1 ||
                        serum.collectionAddress.toLowerCase() ===
                          nftData.contractAddress.toLowerCase() ? (
                          <SerumCard
                            key={index}
                            data={serum}
                            nftData={nftData}
                            isTchamber={true}
                            contract={SerumContract}
                            user={currentWallet}
                            transormParent={() => {
                              getMergedImage(nftData, serum);
                            }}
                          />
                        ) : null
                      )}
                  </Carousel>
                </Col>
              </Row>
            ) : (
              <Row className='justify-content-center my-3'>
                <Col lg={3} md={4} sm={12} className='text-center'>
                  <img
                    src={process.env.PUBLIC_URL + 'images/serumPlaceholder.png'}
                    alt='logo images'
                    className='img-fluid'
                  />
                </Col>
              </Row>
            )}
            <Row className='justify-content-center my-3'>
              <Col lg={3} md={4} sm={12} className='text-center'>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    'images/icons/Arrows_down_animated.gif'
                  }
                  alt='logo images'
                  className='img-fluid'
                  width='30px'
                />
              </Col>
            </Row>

            {transformedImg ? (
              <Row className='justify-content-center mt-3 mb-5'>
                <Col lg={3} md={4} sm={12} className='text-center'>
                  <img
                    className={`showAnimation ${
                      isTransformLoading ? 'showopacity' : ''
                    }`}
                    src={transformedImg}
                    alt='logo images'
                    className='img-fluid'
                  />
                  {showTweeterModal && (
                    <ShareToTwitterModal
                      show={showTweeterModal}
                      handleCloseParent={() => {
                        setShowTweeterModal(false);
                      }}
                      image={transformedImg}
                      selectedMention={selectedMention}
                      currentWallet={currentWallet}
                      selectedChainId={selectedChainId}
                    />
                  )}
                </Col>
              </Row>
            ) : isTransformLoading ? (
              <Row className='justify-content-center mt-3 mb-5'>
                <Col lg={3} md={4} sm={12} className='text-center'>
                  <img
                    src={process.env.PUBLIC_URL + 'images/loader.gif'}
                    className={`showAnimation img-fluid ${
                      isTransformLoading ? 'fadeIn' : 'fadeOut'
                    }`}
                  />
                </Col>
              </Row>
            ) : (
              <Row className='justify-content-center mt-3 mb-5'>
                <Col lg={3} md={4} sm={12} className='text-center'>
                  <img
                    src={
                      process.env.PUBLIC_URL + 'images/transformPlacholder.png'
                    }
                    alt='logo images'
                    className='img-fluid'
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Layout>
    </Fragment>
  );
};

export default TransformationChamber;
