import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./helpers/ScrollToTop";
import Serums from "./pages/Serums";
import TransformationChamber from "./pages/TransformationChamber";
import Nfts from "./pages/Nfts";
import NotFound from "./pages/NotFound";
import NotAuth from "./pages/NotAuth";
import ComingSoon from "./pages/ComingSoon";
import Home from "./pages/Home";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/serums/:nftId?"}`}
            render={(props) => <Serums {...props} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/t-chamber/:nftId?/:data?"}`}
            render={(props) => <TransformationChamber {...props} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/nfts"}`}
            component={Nfts}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
            component={ComingSoon}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/not-found"}`}
            component={NotFound}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/not-auth"}`}
            component={NotAuth}
          />
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
