import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Spinner } from "react-bootstrap";

import ImageUploader from "react-images-upload";
//import { LoadingOutlined } from "@ant-design/icons";




const NewSerum= (props) => {
  const [state, setState] = useState({
    inputs: {
      name: props.data
        ? "Robe² " + props.data.Category + " " + props.data.City
        : "",
      description: "",
      category: props.data ? props.data.Category : "Inception",
      mintOwnerAddr: "",
      duration: "",
      tokens: "",
    },
  });
  //let url="https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg"
  const urlToObject = async (url) => {
    const data = await fetch(url, { cache: "no-store" });
    const blob = await data.blob();
    console.log(blob);
    const file = new File([blob], "image.gif", { type: blob.type });
    setImage([file]);
    // fetch(url, {
    //   mode: "no-cors",
    // }).then((response) => {
    //   const blob = response.blob();
    //   const file = new File([blob], "image.gif", { type: blob.type });
    //   setImage([file]);
    // });
  };

  const [image, setImage] = useState([]);
  const [status, setStatus] = useState("");
  const [tokenId, setTokenId] = useState(null);
  const [minting, setMinting] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(props.data ? props.data.City : "");
  const [isOndropClicked, setIsOndropClicked] = useState(false);


  useEffect(() => {
    if (props.data) {
      urlToObject(process.env.REACT_APP_S3_BUCKET_LINK + props.data.Image);
    }
  }, [props.data]);



  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;
    if (name === "category") {
      //handleLoadCities(value);
    } else if (name === "city") {
      setCity(value);
    }
    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };

  const onDrop = (picture) => {
    setIsOndropClicked(true);
    setImage(picture);
  };

  const mintEnabled = image != null && !!state.inputs.name;

  const startMinting = () => {
    console.log(`minting nft with name ${state.inputs.name}`);
  
  };



  return (
    <Fragment>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='single__account'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                {props.data ? (
                  <ImageUploader
                    singleImage
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={20209230}
                    withPreview={true}
                    label={"Max file size: 20mb, accepted: jpg|gif|png"}
                    defaultImages={[
                      !isOndropClicked
                        ? process.env.REACT_APP_S3_BUCKET_LINK +
                          props.data.Image
                        : image[0]
                        ? URL.createObjectURL(image[0])
                        : "",
                    ]}
                  />
                ) : (
                  <ImageUploader
                    singleImage
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={20209230}
                    withPreview={true}
                    label={"Max file size: 20mb, accepted: jpg|gif|png"}
                  />
                )}
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <input
                  type='text'
                  name='mintOwnerAddr'
                  placeholder='Owner'
                  onChange={(e) => handleChange(e)}
                  className='form-control'
                  value={state.inputs.mintOwnerAddr}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <input
                  type='text'
                  name='name'
                  placeholder='Name'
                  onChange={(e) => handleChange(e)}
                  className='form-control'
                  value={state.inputs.name}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <select
                  onChange={(e) => handleChange(e)}
                  name='category'
                  value={state.inputs.category}
                  className='form-control'
                >
                  <option value='option1'>option1</option>
                  <option value='option2'>option2</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <input
                  type='number'
                  name='duration'
                  placeholder='Duration'
                  onChange={(e) => handleChange(e)}
                  className='form-control'
                  value={state.inputs.duration}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <input
                  type='number'
                  name='tokens'
                  placeholder='Tokens'
                  onChange={(e) => handleChange(e)}
                  className='form-control'
                  value={state.inputs.tokens}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12 text-center'>
                <textarea
                  name='description'
                  placeholder='Description'
                  onChange={(e) => handleChange(e)}
                  className='form-control'
                  value={state.inputs.description}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12 text-left'>
                <button
                  onClick={startMinting}
                  className='btn btn-block btn-warning'
                  disabled={image?.length <= 0 || state.inputs.name === ""}
                >
                  {minting ? <Spinner/> : "Mint!"}
                </button>
                {status}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewSerum;
